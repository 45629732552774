import React, { useState, useEffect } from "react";
import phoneIcon from "../../../Assets/images/school/phone.svg";
import FlagIcon from "../../../Assets/images/school/flag_outline.svg";
import AddressIcon from "../../../Assets/images/school/home_alt_outline.svg";
import MailIcon from "../../../Assets/images/school/Mail.svg";
import schoolImg from "../../../Assets/images/School.png";
import ViewIcon from "../../../Assets/images/crud/show.svg";
import UpdateIcon from "../../../Assets/images/crud/edit.svg";
import DeleteIcon from "../../../Assets/images/crud/trash_full.svg";
import SchoolFormLayout from "../Layouts/SchoolFormLayout.tsx";
import {
  AcceesLevel,
  SCHOOL_FORM_DATA,
} from "../../../Shared/Utils/Constants.tsx";
import MoreIcon from "../../../Assets/images/coolicon.svg";
import CustomModal from "../../../Component/CustomModal.tsx";
import image001 from "../../../Assets/images/school/schoolDefault.jpg";
import image002 from "../../../Assets/images/school/002.jpg";
import image003 from "../../../Assets/images/school/003.jpg";
import image004 from "../../../Assets/images/school/004.jpg";
import { loadSchool, deleteSchool, updateSchool } from "./SchoolFunctions.tsx";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationModal from "../../../Component/ConfirmationModal.tsx";
import { checkValidation } from "../../../Shared/Components/Utilities/Validator.tsx";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router";
import Tooltip from "@mui/material/Tooltip";

const SchoolList = ({ render }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [schoolDetails, setSchoolDetails] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(SCHOOL_FORM_DATA);
  const [renderPage, setRenderPage] = useState(false);
  const [isProgerss, setIsProgress] = useState(false);
  const [school, setSchools] = useState<any>([]);
  const [selectedSchoolIds, setSelectedSchoolIds] = useState({
    Id: "",
    RootFolderId: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const usertype: any = localStorage.getItem("user-type-id");
  const navigate = useNavigate();

  useEffect(() => {
    setIsProgress(true);
    loadSchool(setSchools, setIsProgress);
  }, [render, renderPage]);

  const handleOnClick = () => {
    setShowModal(true);
    setFormData({
      schoolCode: {
        id: formData?.schoolCode?.id,
        isRequired: true,
        value: schoolDetails?.Code,
        isDisabled: false,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
      schoolName: {
        id: formData?.schoolName?.id,
        isRequired: true,
        value: schoolDetails?.Name,
        isDisabled: false,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
      description: {
        id: formData?.description?.id,
        isRequired: false,
        value: schoolDetails?.Description,
        isDisabled: false,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
      address: {
        id: formData?.address?.id,
        isRequired: false,
        value: schoolDetails?.Address,
        isDisabled: false,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
      email: {
        id: formData?.email?.id,
        isRequired: true,
        value: schoolDetails?.Email,
        isDisabled: false,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
      telenumber: {
        id: formData?.telenumber?.id,
        isRequired: true,
        value: schoolDetails?.TelephoneNumber,
        isDisabled: false,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
      country: {
        id: formData?.country?.id,
        value: {
          countryName: schoolDetails?.Country,
          countryCode: schoolDetails?.CountryCode,
        },
        isError: false,
        errorMessage: "",
      },
    });
  };

  const handleOnDelete = () => {
    setShowConfirmModal(true);
  };

  const handleUpdate = () => {
    const response = checkValidation(formData, setFormData);
    if (response) {
      updateSchool(
        selectedSchoolIds,
        formData,
        setShowModal,
        setRenderPage,
        renderPage,
        setIsProgress
      );
      handleClose();
    }
  };

  const handleClick = (event, item) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
    setSelectedSchoolIds({
      Id: item?.Id,
      RootFolderId: item?.RootFolderId,
    });
    setSchoolDetails(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = (item) => {
    if (item) {
      navigate(`/schools/${item?.RootFolderId}`);
      handleClose();
    } else {
      navigate(`/schools/${selectedSchoolIds?.RootFolderId}`);
      handleClose();
    }
  };

  return (
    <div>
      <section className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <div className="d-flex justify-content-start flex-row w-100 flex-wrap">
              {isProgerss ? (
                <div className="loading-container">
                  <CircularProgress color="primary" size={30} />
                </div>
              ) : (
                <>
                  {school.length != 0 ? (
                    school.map((item, index) => {
                      return (
                        <div
                          className="p-2 bg-light rounded mb-2 mx-2"
                          style={{ width: "320px" }}
                          key={index}
                        >
                          <div>
                            <div className="p-2 bg-light rounded mb-1  ">
                              <div
                                className="bg-white rounded shadow-sm card-hover"
                                onDoubleClick={() => handleView(item)}
                              >
                                <div className="position-relative">
                                  <div className="   d-flex justify-content-between ">
                                    <Tooltip title="More" placement="top">
                                      <a
                                        id="basic-button"
                                        className="folder dropdown-toggle px-0 position-absolute end-0 pt-2"
                                        aria-controls={
                                          open ? "basic-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        onClick={(event) =>
                                          handleClick(event, item)
                                        }
                                      >
                                        <img src={MoreIcon} alt="" />
                                      </a>
                                    </Tooltip>
                                  </div>

                                  <div className="py-1 position-absolute ms-2">
                                    <span className="text-secondary  badge badge-light fw-light">
                                      Code : {item.Code}
                                    </span>
                                  </div>

                                  <img
                                    src={
                                      item?.LogoUrl ? item?.LogoUrl : image001
                                    }
                                    alt=""
                                    style={{ height: "50px;" }}
                                    className="w-100"
                                  />
                                </div>
                                <div
                                  className="p-2"
                                  style={{ minHeight: "195px" }}
                                >
                                  <div>
                                    <p className="d-flex  justify-content-between mb-2">
                                      <span className="fw-bold">
                                        {item?.Name}
                                      </span>{" "}
                                    </p>
                                    <ul className="list-group list-group-flush small">
                                      {item?.Address ? (
                                        <li className="list-group-item px-0 pb-1 pt-0">
                                          <img
                                            src={AddressIcon}
                                            alt=""
                                            style={{ width: "18px" }}
                                            className="me-2"
                                          />{" "}
                                          {item?.Address}
                                        </li>
                                      ) : (
                                        <></>
                                      )}

                                      <li className="list-group-item px-0 pb-1 pt-0">
                                        <img
                                          src={MailIcon}
                                          alt=""
                                          style={{ width: "15px" }}
                                          className="me-2"
                                        />{" "}
                                        {item?.Email}{" "}
                                      </li>
                                      <li className="list-group-item px-0 pb-1 pt-0">
                                        <img
                                          src={phoneIcon}
                                          alt=""
                                          style={{ width: "15px" }}
                                          className="me-2"
                                        />{" "}
                                        {item?.TelephoneNumber}
                                      </li>
                                      <li className="list-group-item px-0 pb-1 pt-0">
                                        <img
                                          src={FlagIcon}
                                          alt=""
                                          style={{ width: "18px" }}
                                          className="me-2"
                                        />{" "}
                                        {item?.Country}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="w-100 p-2">No Rows To Show</div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <>
          {AcceesLevel.some(
            (item) =>
              parseInt(usertype) === item.Id &&
              item.AllowTo.includes("EDIT_SCL")
          ) && (
            <MenuItem onClick={handleOnClick}>
              <a className="dropdown-item align-items-center d-flex" href="#">
                <img src={UpdateIcon} alt="" className="me-2" />
                <span className="small">Edit</span>
              </a>
            </MenuItem>
          )}

          {AcceesLevel.some(
            (item) =>
              parseInt(usertype) === item.Id &&
              item.AllowTo.includes("VIEW_SCL")
          ) && (
            <MenuItem onClick={() => handleView(null)}>
              <a className="dropdown-item align-items-center d-flex" href="#">
                <img src={ViewIcon} alt="" className="me-2" />{" "}
                <span className="small">View</span>
              </a>
            </MenuItem>
          )}

          {AcceesLevel.some(
            (item) =>
              parseInt(usertype) === item.Id &&
              item.AllowTo.includes("DELETE_SCL")
          ) && (
            <MenuItem onClick={handleOnDelete}>
              <a className="dropdown-item align-items-center d-flex" href="#">
                <img src={DeleteIcon} alt="" className="me-2" />{" "}
                <span className="small text-danger">Delete</span>
              </a>
            </MenuItem>
          )}
        </>
      </Menu>

      <CustomModal
        IsOpen={showModal}
        IsClose={setShowModal}
        Title={"Update school"}
        isProgress={isProgerss}
        onSuccessCallBack={() => handleUpdate()}
        element={
          <SchoolFormLayout setFormData={setFormData} formData={formData} />
        }
        isDisabled={false}
      />

      <ConfirmationModal
        IsOpen={showConfirmModal}
        IsClose={setShowConfirmModal}
        Title={"Delete School"}
        isProgress={isProgerss}
        element={"Do you want to delete this ?"}
        onSuccessCallBack={() => {
          deleteSchool(
            selectedSchoolIds,
            setRenderPage,
            renderPage,
            setShowConfirmModal,
            setIsProgress
          );
          handleClose();
        }}
      />
    </div>
  );
};

export default SchoolList;

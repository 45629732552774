import React, { useState } from "react";
import SignIn from "./SignIn.tsx";

const SignOutPage = () => {
  const [isSignIn, setIsSignIn] = useState(false);

  const SignInBtn = () => {
    setIsSignIn(true);
  };

  return (
    <>
      {isSignIn ? (
        <SignIn />
      ) : (
        <div className="sign-out-page">
          <h2>Signed Out</h2>
          <p>You have been signed out of your account.</p>
          <button className="sign-in-button" onClick={() => SignInBtn()}>
            Sign In Again
          </button>
        </div>
      )}
    </>
  );
};

export default SignOutPage;

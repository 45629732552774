import "./App.css";
import TopNavBar from "./Component/Header/TopNavBar.tsx";
import Progressbar from "./Component/ProgressBar.tsx";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { UploaderProviderContext } from "./Component/ProgressBarProvider.tsx";
import { useMsal } from "@azure/msal-react";
import Home from "./Features/Home/Components/Home.tsx";
import Subject from "./Features/Subject/Components/Subject.tsx";
import School from "./Features/School/Components/School.tsx";
import User from "./Features/User/Components/User.tsx";
import Toast from "./Component/Toast.tsx";
import CommonService from "./Shared/Services/CommonService.tsx";
import { AcceesLevel } from "./Shared/Utils/Constants.tsx";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

const App = ({ token }) => {
  const { accounts, instance, inProgress } = useMsal();
  const [userInfo, setUserInfo] = useState(false);
  const dataFetcher = new CommonService();

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        let userResponse;
        if (accounts[0]?.username) {
          userResponse = await dataFetcher.getUserByEmail(
            accounts[0]?.username,
            token
          );
        }
        setUserInfo(userResponse);
      } catch (error) {
        console.log("Get User Error : ", error);
      }
    };

    getUserDetails();
  }, [accounts]);

  return (
    <ThemeProvider theme={theme}>      
    <UploaderProviderContext>
      <BrowserRouter>
        <Toast />
        <div>
          <Progressbar />
          <TopNavBar userInfo={userInfo} />
        </div>
        <Routes>
          <Route path="/" element={<Navigate to="/vce" replace />} />
          <Route path="/login" element={<Navigate to="/vce" replace />} />
          <Route path="/vce" element={<Home userInfo={userInfo} />} />
          <Route path="/vce/:id" element={<Home userInfo={userInfo} />} />
          <Route path="/subjects" element={<Subject />} />
          <Route path="/schools" element={<School />} />
          <Route path="/subjects/:id" element={<Subject />} />
          <Route path="/schools/:id" element={<School />} />
          {AcceesLevel.some(
            (item) =>
              userInfo?.UserTypeId === item.Id && item.AllowTo.includes("ALL")
          ) ? (
            <>
              <Route path="/users" element={<User />} />
              <Route path="/users/:id" element={<User />} />
            </>
          ) : (
            <></>
          )}
        </Routes>
      </BrowserRouter>
    </UploaderProviderContext>
    </ThemeProvider>

  );
};

export default App;

import { BlobServiceClient } from "@azure/storage-blob";
import ServiceClient from "../../Services/ServiceClient.tsx";
import {config} from "../../Utils/config.tsx";
import { createFile } from "../FolderContainer/FolderFunctions.tsx";

export const uploadFile = async (fileData, handleCount) => {
  const blobName = fileData?.file?.name;
  const contentType = fileData?.file?.type;
  let baseUrl;
  let uuidFileName;

  ServiceClient.GET(
    config.file_uploader.BASE_URL + config.file_uploader.END_POINTS.UPLOAD_FILE_URL,
    `fileName=${blobName}`
  )
    .then((result) => {
      uuidFileName = result?.NewFileName;
      baseUrl = result?.LocationUrl;
      createConnection(
        baseUrl,
        uuidFileName,
        contentType,
        fileData,
        handleCount
      );
    })
    .catch((error) => {
      console.log(error);
    });
};

const createConnection = async (
  baseUrl,
  uuidFileName,
  contentType,
  fileData,
  handleCount
) => {
  const blobServiceClient = new BlobServiceClient(baseUrl);
  const containerClient = blobServiceClient.getContainerClient("");
  const blobClient = containerClient.getBlockBlobClient(uuidFileName);

  try {
    const response = await blobClient.uploadData(fileData?.file, {
      blobHTTPHeaders: {
        blobContentType: contentType,
      },
      onProgress: (env) => {
        const progress = (env.loadedBytes / fileData?.file.size) * 100;
        handleCount({
          count: progress.toFixed(2),
          isProgressBar: true,
          filename : fileData?.file?.name,
          isUpload : true,
          isEnabled : true
        });
      },
    });
    
    handleCount({
      count: 0,
      isProgressBar: false,
      filename : "",
      isUpload : true,
      isEnabled : false
    });

    createFile(
      fileData?.file?.name,
      fileData?.folderId,
      "Test",
      uuidFileName,
      fileData?.refreshFolderContent,
      fileData?.setShowModal
    )
  } catch (error) {
    console.log("File Upload Error : ", error);
  }
};

// @ts-ignore
import {
  SubjectCreate,
  SchoolCreate,
  FolderCreate,
  FileUpload,
  UsersCreate,
} from "../../Models/Interfaces.tsx";

const requiredField = {
  isRequired: true,
  isInvalid: false,
};

const notRequiredField = {
  isRequired: false,
  isInvalid: false,
};

export let isPageRender: boolean;

export const FILE_ICON = {
  doc: "../../../Assets/images/Files/DOC.svg",
  docx: "../../../Assets/images/Files/DOCX.svg",
  ppt: "../../../Assets/images/Files/PPT.svg",
  pptx: "../../../Assets/images/Files/PPT.svg",
  xlsx: "../../../Assets/images/Files/XSL.svg",
  mp4: "../../../Assets/images/Files/MP4.svg",
  mp3: "../../../Assets/images/Files/MP3.svg",
  csv: "../../../Assets/images/Files/CSV.svg",
  ogg: "../../../Assets/images/Files/DOC.svg",
  jpg: "../../../Assets/images/Files/JPG.svg",
  jpeg: "../../../Assets/images/Files/JPG.svg",
  png: "../../../Assets/images/Files/PNG.svg",
};

export const SUBJECT_FORM_DATA: SubjectCreate = {
  subjectName: {
    id: "subjectName",
    ...requiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  description: {
    id: "description",
    ...notRequiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
};

export const SCHOOL_FORM_DATA: SchoolCreate = {
  schoolCode: {
    id: "schoolCode",
    ...requiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  schoolName: {
    id: "schoolName",
    ...requiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  description: {
    id: "description",
    ...notRequiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  address: {
    id: "address",
    ...notRequiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  email: {
    id: "email",
    ...notRequiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  telenumber: {
    id: "telenumber",
    ...notRequiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  country: {
    id: "country",
    ...requiredField,
    value: { countryCode: "", countryName: "" },
    isError: false,
    errorMessage: "",
  }
};

export const FOLDER_FORM_DATA: FolderCreate = {
  folderName: {
    id: "folderName",
    ...requiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  folderDescription: {
    id: "folderDescription",
    ...notRequiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
};

export const FILE_FORM_DATA: FileUpload = {
  fileName: {
    id: "fileName",
    ...requiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  file: {
    id: "file",
    ...requiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
};

export const VARIABLES = {
  NAV_PATH: "nav",
  COM_PATH: "comp",
};

export const progressDetails = {
  isProgressBar: true,
  count: 0,
};

export const USER_FORM_DATA: UsersCreate = {
  FirstName: {
    id: "FirstName",
    ...notRequiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  LastName: {
    id: "LastName",
    ...requiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  PersonalEmail: {
    id: "PersonalEmail",
    ...requiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  },
  Country: {
    id: "Country",
    ...requiredField,
    value: {
      countryCode: "",
      countryName: "",
    },
    isError: false,
    errorMessage: "",
  },
  UserType: {
    id: "UserType",
    ...requiredField,
    value: {
      UserTypeId: "",
      UserTypeValue: "",
    },
    isError: false,
    errorMessage: "",
  },
  PhoneNumber: {
    id: "PhoneNumber",
    ...requiredField,
    value: "",
    isDisabled: false,
    isError: false,
    errorMessage: "",
  }
};

export const userTypes = {
  1: "Admin",
  2: "Mentor",
  3: "Co-Ordinator",
  4: "Teacher",
};

export const AcceesLevel = [
  { Id: 1, AllowTo: ["ALL" , "SCHL", "SUBJ", "CRT_FOLDR", "UPD_FILE", "FILE_VIEW_DOWNLOAD" , "FILE_DELETE", "EDIT_FOLDER", "DELETE_FOLDER", "EDIT_SCL", "VIEW_SCL", "DELETE_SCL", "EDIT_SUB", "VIEW_SUB", "DELETE_SUB"] },
  { Id: 2, AllowTo: ["VIEW", "HIDE_BTN", "SUBJ", "CRT_FOLDR", "UPD_FILE", "FILE_VIEW_DOWNLOAD", "FILE_DELETE", "EDIT_FOLDER", "DELETE_FOLDER", "VIEW_SUB"] },
  { Id: 3, AllowTo: ["VIEW", "HIDE_BTN", "SCHL", "SUBJ", "FILE_VIEW_DOWNLOAD", "VIEW_SCL", "VIEW_SUB"] },
  { Id: 4, AllowTo: ["VIEW", "HIDE_BTN", "SCHL", "SUBJ", "FILE_VIEW_DOWNLOAD", "VIEW_SCL", "VIEW_SUB"] },
];

export const ModuleType = {
  VCE: "Vce",
  SCHOOL: "School",
  SUBJECT: "Subject"
};

export const msalLibraryInstance = {
    account : undefined,
    instance : undefined
};
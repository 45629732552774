import React, { useEffect, useState } from "react";
// @ts-ignore
import CreateSubjectIcon from "../../../Assets/images/subjectIcon.svg";
import CustomModal from "../../../Component/CustomModal.tsx";
// @ts-ignore
import UserFormLayout from "../Layouts/UserFormLayout.tsx";
// @ts-ignore
import { USER_FORM_DATA } from "../../../Shared/Utils/Constants.tsx";
// @ts-ignore
import { createUser } from "./UserFunction.tsx";
import { checkValidation } from "../../../Shared/Components/Utilities/Validator.tsx";
import { getAllSchool, getAllSubject } from "./UserFunction.tsx";

const UserContent = ({ callbackfunction, callbackstatus }) => {
  const [showModal, setShowModal] = useState(false);
  const [isProgerss, setIsProgress] = useState(false);
  const [formData, setFormData] = useState(USER_FORM_DATA);
  const [selectedSubject, setSelectedSubject] = useState<any>([]);
  const [selectedSchool, setSelectedSchool] = useState<any>([]);
  const [subjects, setSubjects] = useState([]);
  const [schools, setSchools] = useState([]);

  const handleOpen = () => {
    setShowModal(true);
    setFormData(USER_FORM_DATA);
  };

  const handleClick = () => {
    const selectedDetails = {
      subject: selectedSubject,
      school: selectedSchool,
    };
    const response = checkValidation(formData, setFormData);
    if (response) {
      createUser(
        formData,
        selectedDetails,
        setShowModal,
        callbackfunction,
        callbackstatus,
        setIsProgress
      );
    }
  };

  useEffect(() => {
    execute();
  }, []);

  async function execute() {
    setSubjects(await getAllSubject());
    setSchools(await getAllSchool());
  }

  return (
    <div className="main">
      <section className="container-fluid my-3">
        <div className="row  justify-content-between">
          <div className="col-12 col-lg-6">
            <h5 className="">USERS</h5>
            {/* <div className=" small rounded-sm ">
              <p className="small mb-1">
              Find what you need faster … Home is your new landing page and surfaces your most relevant files and folders
              </p>
            </div> */}
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-start justify-content-md-end">
            <button
              className="btn btn-outline-secondary me-2 d-flex align-items-center "
              onClick={handleOpen}
            >
              <img src={CreateSubjectIcon} className="me-2" alt="" />
              <span>Add New User</span>
            </button>
          </div>
        </div>
      </section>
      <CustomModal
        IsOpen={showModal}
        IsClose={setShowModal}
        Title={"Create Users"}
        isProgress={isProgerss}
        onSuccessCallBack={() => {
          handleClick();
        }}
        element={
          <UserFormLayout
            setFormData={setFormData}
            formData={formData}
            subjects={subjects}
            schools={schools}
            selectedSubject={selectedSubject}
            selectedSchool={selectedSchool}
            setSelectedSubject={setSelectedSubject}
            setSelectedSchool={setSelectedSchool}
            isUpdate={false}
            isView={false}
          />
        }
        isDisabled={false}
      />
    </div>
  );
};

export default UserContent;

import { SubjectDataFetcher } from "./SubjectDataFetcher.tsx";
import {config} from "../../../Shared/Utils/config.tsx";
import ServiceClient from "../../../Shared/Services/ServiceClient.tsx";
import { toast } from "react-toastify";

export default class SubjectService extends SubjectDataFetcher {
  async createSubject(data: any): Promise<any> {
    const response = await ServiceClient.POST(
      config.subject.BASE_URL + config.subject.END_POINTS.CREATE_SUBJECT,
      data
    );

    return response;
  }

  async getAllSubjects(): Promise<any> {
    const response = await ServiceClient.GET(
      config.subject.BASE_URL + config.subject.END_POINTS.GET_ALL_SUBJECT,
      null
    );

    return response;
  }

  async getSubject(parentId: any): Promise<any> {
    const response = await ServiceClient.GET(
      config.subject.BASE_URL + config.subject.END_POINTS.GET_SUBJECT,
      parentId
    );

    return response;
  }

  async updateSubject(data: any): Promise<any> {
    const response = await ServiceClient.PUT(
      config.subject.BASE_URL + config.subject.END_POINTS.UPDATE_SUBJECT,
      data
    );

    return response;
  }

  async deleteSubject(subjectId: any): Promise<any> {
    const response = ServiceClient.DELETE(config.subject.BASE_URL + config.subject.END_POINTS.DELETE_SUBJECT + `/${subjectId}`);
    const result = await response;  
    if(result.code){
      toast.error(result?.response?.data?.Message);
    }else {
      toast.success("Subject Deleted")
    }
    return response;
  }
}

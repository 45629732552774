import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";

const FileViewer = ({ fileInfo }) => {
  const [element, setElement] = useState<any>(null);
  
  useEffect(() => {
    const extenstion = fileInfo?.FileMetaData?.FileExtention?.replace(".", "")?.toLowerCase();
    const docs = [
      {
        uri: fileInfo?.Url,
        fileType: extenstion,
        fileName: "",
      },
    ];

    if (
      extenstion === "jpg" ||
      extenstion === "jpeg" ||
      extenstion === "png" ||
      extenstion === "pdf" ||
      extenstion === "pptx" ||
      extenstion === "xlsx" ||
      extenstion === "docx" ||
      extenstion === "doc" ||
      extenstion === "csv" ||
      extenstion === "ppt"
    ) {
      setElement(
        <DocViewer
          style={{ height: 'calc(100vh - 300px)' }}
          documents={docs}
          pluginRenderers={DocViewerRenderers}
        />
      );
    } else if (extenstion === "mp3" || extenstion === "ogg") {
      setElement(<ReactAudioPlayer 
        src={fileInfo?.Url} 
        autoPlay 
        controls
        className="w-100 mt-3"
        />);
    } else if (extenstion === "mp4") {
      setElement(
        <ReactPlayer
          width="100%"
          url={fileInfo?.Url}
          type="video/mp4"
          controls={true}
          playing
          className="mt-3"
        />
      );
    }
  }, [fileInfo]);

  return <div>{element ? element : <></>}</div>;
};

export default FileViewer;

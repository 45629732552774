import { SchoolDataFetcher } from "./SchoolDataFetcher.tsx";
import {config} from "../../../Shared/Utils/config.tsx";
import ServiceClient from "../../../Shared/Services/ServiceClient.tsx";
import { toast } from "react-toastify";

export default class SchoolService extends SchoolDataFetcher {
  async createSchool(data: any): Promise<any> {
    const response = await ServiceClient.POST(
      config.school.BASE_URL + config.school.END_POINTS.CREATE_SCHOOL,
      data
    );

    return response;
  }

  async getAllSchool(): Promise<any> {
    const response = ServiceClient.GET(
      config.school.BASE_URL + config.school.END_POINTS.GET_ALL_SCHOOL,
      null
    );
    return response;
  }

  async getSchool(parentId: any): Promise<any> {
    const response = ServiceClient.GET(
      config.school.BASE_URL + config.school.END_POINTS.GET_SCHOOL,
      parentId
    );
    return response;
  }

  async updateSchool(data: any): Promise<any> {
    const response = await ServiceClient.PUT(
      config.school.BASE_URL + config.school.END_POINTS.UPDATE_SCHOOL,
      data
    );

    return response;
  }

  async deleteSchool(schoolId: any): Promise<any> {
    const response = await ServiceClient.DELETE(
      config.school.BASE_URL +
        config.school.END_POINTS.DELETE_SCHOOL +
        `/${schoolId}`
    );

    return response;
  }
}

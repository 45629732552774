import React, { useEffect, useState } from "react";
import "../../../Assets/Global.css";
import { useParams } from "react-router-dom";
import FolderContent from "../../../Shared/Components/FolderContainer/FolderContent.tsx";
import UserContent from "./UserContent.tsx";
import UserList from "./UserListV2.tsx";
import { getAllSchool, getAllSubject } from "./UserFunction.tsx";
const User = () => {
  const [status, setStatus] = useState(false);
  const [element, setElement] = useState<any>(null);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setElement(<FolderContent rootFolderId={id} moduleType={null} />);
    } else {
      setElement(
        <>
          <UserContent callbackfunction={setStatus} callbackstatus={status} />
          <UserList render={status} />
        </>
      );
    }
  }, [id, status]);

  return <div>{element}</div>;
};

export default User;

import React, { useState, useEffect } from "react";
import "../../../Assets/Global.css";
import View from "../../../Assets/images/crud/show.svg";
import UpdateIcon from "../../../Assets/images/crud/edit.svg";
import DeleteIcon from "../../../Assets/images/crud/trash_full.svg";
import CustomModal from "../../../Component/CustomModal.tsx";
import SubjectFormLayout from "../Layouts/SubjectFormLayout.tsx";
import {
  AcceesLevel,
  SUBJECT_FORM_DATA,
} from "../../../Shared/Utils/Constants.tsx";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  deleteSubject,
  loadSubjects,
  updateSubject,
} from "./SubjectFunctions.tsx";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationModal from "../../../Component/ConfirmationModal.tsx";
import { checkValidation } from "../../../Shared/Components/Utilities/Validator.tsx";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreIcon from "../../../Assets/images/coolicon.svg";
import Tooltip from "@mui/material/Tooltip";
import DefaultImg from "../../../Assets/images/Subjects/default-subject.png";

const SubjectList = ({ render }) => {
  const [subject, setSubjects] = useState<any>([]);
  const [subjectDetails, setSubjectDetails] = useState<any>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const usertype: any = localStorage.getItem("user-type-id");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(SUBJECT_FORM_DATA);
  const [renderPage, setRenderPage] = useState(false);
  const [isProgerss, setIsProgress] = useState(false);
  const [selectedSubjectIds, setSelectedSubjectIds] = useState({
    Id: "",
    RootFolderId: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    setIsProgress(true);
    loadSubjects(setSubjects, setIsProgress);
  }, [render, renderPage]);

  const handleOnClick = () => {
    setShowModal(true);
    setFormData({
      subjectName: {
        value: subjectDetails?.Name,
        id: formData?.subjectName?.id,
        isRequired: true,
        isDisabled: false,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
      description: {
        id: formData?.description?.id,
        isRequired: false,
        value: subjectDetails?.Description,
        isDisabled: false,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
    });
  };

  const handleDelete = () => {
    setShowConfirmModal(true);
  };

  const handleUpdate = () => {
    const response = checkValidation(formData, setFormData);
    if (response) {
      updateSubject(
        selectedSubjectIds,
        formData,
        setShowModal,
        setRenderPage,
        renderPage,
        setIsProgress
      );
    }
    handleClose();
  };

  const handleView = (item) => {
    if (item) {
      navigate(`/subjects/${item?.RootFolderId}`);
      handleClose();
    } else {
      navigate(`/subjects/${selectedSubjectIds?.RootFolderId}`);
      handleClose();
    }
  };

  const handleClick = (event, item) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
    setSelectedSubjectIds({
      Id: item?.Id,
      RootFolderId: item?.RootFolderId,
    });
    setSubjectDetails(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <section className="container-fluid px-1">
        <div className="d-flex justify-content-start flex-row w-100 flex-wrap">
          {isProgerss ? (
            <div className="loading-container">
              <CircularProgress color="primary" size={30} />
            </div>
          ) : (
            <>
              {subject.length != 0 ? (
                subject.map((item, index) => {
                  return (
                    <div
                      className="p-2 bg-light rounded mb-2 mx-2"
                      style={{ width: "220px" }}
                    >
                      <div
                        className="bg-white rounded shadow-sm card-hover"
                        onDoubleClick={() => handleView(item)}
                      >
                        <div className=" d-flex justify-content-center flex-column align-items-center">
                          <div className="bg-primary-cad w-100 rounded-top  subject-header ">
                            <div className="  py-1 d-flex justify-content-end">
                              <Tooltip title="More" placement="top">
                                <a
                                  href="#"
                                  id="basic-button"
                                  className="folder dropdown-toggle px-0"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(event) => handleClick(event, item)}
                                >
                                  <img src={MoreIcon} alt="" />
                                </a>
                              </Tooltip>
                            </div>
                            <div className="text-center d-flex align-items-center justify-content-center">
                              <img
                                src={item?.LogoUrl ? item?.LogoUrl : DefaultImg}
                                className="w-35"
                              />
                            </div>
                          </div>
                          <div className="subject-body d-flex flex-column align-items-center justify-content-center">
                            <p className="text-center fw-bold pt-2 mb-0 px-1">
                              {" "}
                              {item?.Name}
                            </p>
                            {/* <div className="px-2 pb-2 text-center small">
                              {item?.Description}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="table-data-box">
                  <div className="row table-data-margin center-content">
                    <br></br>
                    No Rows To Show
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <>
          {AcceesLevel.some(
            (item) =>
              parseInt(usertype) === item.Id &&
              item.AllowTo.includes("EDIT_SUB")
          ) && (
            <MenuItem onClick={handleOnClick}>
              <a className="dropdown-item align-items-center d-flex">
                <img src={UpdateIcon} alt="" className="me-2" />
                <span className="small">Edit</span>
              </a>
            </MenuItem>
          )}

          {AcceesLevel.some(
            (item) =>
              parseInt(usertype) === item.Id &&
              item.AllowTo.includes("VIEW_SUB")
          ) && (
            <MenuItem onClick={() => handleView(null)}>
              <a className="dropdown-item align-items-center d-flex">
                <img src={View} alt="" className="me-2" />{" "}
                <span className="small">View</span>
              </a>
            </MenuItem>
          )}

          {AcceesLevel.some(
            (item) =>
              parseInt(usertype) === item.Id &&
              item.AllowTo.includes("DELETE_SUB")
          ) && (
            <MenuItem onClick={handleDelete}>
              <a className="dropdown-item align-items-center d-flex">
                <img src={DeleteIcon} alt="" className="me-2" />{" "}
                <span className="small text-danger">Delete</span>
              </a>
            </MenuItem>
          )}
        </>
      </Menu>

      <CustomModal
        IsOpen={showModal}
        IsClose={setShowModal}
        Title={"Update Subject"}
        isProgress={isProgerss}
        onSuccessCallBack={() => handleUpdate()}
        element={
          <SubjectFormLayout setFormData={setFormData} formData={formData} />
        }
        isDisabled={false}
      />

      <ConfirmationModal
        IsOpen={showConfirmModal}
        IsClose={setShowConfirmModal}
        Title={"Delete Subject"}
        isProgress={isProgerss}
        element={"Do you want to delete this ?"}
        onSuccessCallBack={() => {
          deleteSubject(
            selectedSubjectIds,
            setRenderPage,
            renderPage,
            setShowConfirmModal,
            setIsProgress
          );
          handleClose();
        }}
      />
    </div>
  );
};

export default SubjectList;

import { InteractionRequiredAuthError } from "@azure/msal-common";
import {
  acquireTokenRedirectRequest,
  loginRequest,
} from "../../Authentication/authConfig.js";
import { msalInstance } from "../../index.js";

export const refreshAcquireToken = async () => {
  try {
    const activeAccount: any = msalInstance.getAllAccounts();
    const res: any = await msalInstance
      .acquireTokenSilent({
        ...loginRequest,
        account: activeAccount[0],
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return msalInstance.acquireTokenRedirect(acquireTokenRedirectRequest);
        }
      });

    return res.accessToken;
  } catch (error) {
    console.log("Error : ", error);
  }
};

// @ts-ignore
import { toast } from "react-toastify";
import CommonService from "../../Services/CommonService.tsx";
import fileList from "../../Utils/Files-urls.json";
const dataFetcher = new CommonService();

export const loadFolderContent = (
  currentFolderId,
  setFolderData,
  setFileData,
  setPath,
  setIsProgress
) => {
  dataFetcher
    .getFolderContent(currentFolderId)
    .then((result) => {
      setFolderData(result.Folders);
      const updatedFilesList = result.Files?.map((item) => {
        switch (item?.FileExtention?.toLowerCase()) {
          case ".mp4":
            return { ...item, img: fileList?.mp4 };
          case ".doc":
            return { ...item, img: fileList?.doc };
          case ".docx":
            return { ...item, img: fileList?.docx };
          case ".csv":
            return { ...item, img: fileList?.csv };
          case ".pptx":
            return { ...item, img: fileList?.pptx };
          case ".ppt":
            return { ...item, img: fileList?.pptx };
          case ".jpeg":
            return { ...item, img: fileList?.jpeg };
          case ".jpg":
            return { ...item, img: fileList?.jpg };
          case ".mp3":
            return { ...item, img: fileList?.mp3 };
          case ".png":
            return { ...item, img: fileList?.png };
          case ".pdf":
            return { ...item, img: fileList?.pdf };
          case ".xlsx":
            return { ...item, img: fileList?.xlsx };
          case ".xsl":
            return { ...item, img: fileList?.xlsx };
          default:
            return { ...item, img: fileList?.doc };
        }
      });
      setFileData(updatedFilesList);
      setPath(result.Path);
      setIsProgress(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.Message);
      console.log("Folder Content Error : ", error);
    });
};

export const loadFolder = (currentFolderId, setFolderDetail) => {
  dataFetcher
    .getFolder(currentFolderId)
    .then((result) => {
      setFolderDetail(result);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.Message);
      console.log("Folder Content Error : ", error);
    });
};

export const createFolder = (
  formData,
  rootFolderId,
  refreshFolderContent,
  setShowModal,
  isProgerss
) => {
  isProgerss(true);
  const data = {
    ParentFolderId: rootFolderId,
    Name: formData?.folderName?.value,
    Description: formData?.folderDescription?.value,
  };

  dataFetcher
    .createFolder(data)
    .then(() => {
      refreshFolderContent();
      isProgerss(false);
      setShowModal(false);
      toast.success("Folder Created");
    })
    .catch((error) => {
      isProgerss(false);
      toast.error(error?.response?.data?.Message);
      console.log("Create Folder Error : ", error);
    });
};

export const createFile = (
  fileName,
  folderId,
  description,
  fileStorageId,
  refreshFolderContent,
  setShowModal
) => {
  const data = {
    Name: fileName,
    Description: description,
    FolderId: folderId,
    FileStorageId: fileStorageId,
  };

  dataFetcher
    .createFile(data)
    .then(() => {
      refreshFolderContent();
      setShowModal(false);
      toast.success("File Created");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.Message);
      console.log("Create Folder Error : ", error);
    });
};

export const updateFolder = (
  formData,
  rootFolderId,
  currentFolderId,
  refreshFolderContent,
  setShowModal,
  setIsProgress
) => {
  const data = {
    Id: currentFolderId,
    Name: formData?.folderName?.value,
    Description: formData?.folderDescription?.value,
    ParentFolderId: rootFolderId,
  };

  dataFetcher
    .updateFolder(data)
    .then(() => {
      refreshFolderContent();
      setShowModal(false);
      toast.success("Folder Updated");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.Message);
      console.log(error);
    });
};

export const getFileContent = (fileId, handleDetails) => {
  dataFetcher
    .getFile(fileId)
    .then((result) => {
      downloadFileRequest(result, handleDetails);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getFileContentForView = (fileId, setFileDetails) => {
  dataFetcher
    .getFile(fileId)
    .then((result) => {
      setFileDetails(result);
    })
    .catch((error) => {
      console.log(error);
    });
};

const downloadFileRequest = (data, handleDetails) => {
  dataFetcher
    .downloadFile(
      data?.Url,
      data?.FileMetaData?.FileExtention,
      data,
      handleDetails
    )
    .then((result) => {})
    .catch((error) => {
      console.log(error);
    });
};

export const deleteFile = (fileId) => {
  dataFetcher
    .deleteFile(fileId)
    .then((result) => {})
    .catch((error) => {
      console.log("Delete File : ", error);
    });
};

export const getFolderContent = async (currentFolderId) => {
  const result = await dataFetcher.getFolderContent(currentFolderId);
  return result;
};

export const deleteFolder = (folderId) => {
  var count;
  dataFetcher
    .deleteFolder(folderId)
    .then((result) => {
      count = count + 1;
      if (count <= 1) {
        toast.success("Folder Deleted");
      }
    })
    .catch((error) => console.log(error));
};

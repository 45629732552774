import React, { useEffect, useState } from "react";
import "../../../Assets/Global.css";
import FolderContent from "../../../Shared/Components/FolderContainer/FolderContent.tsx";
// @ts-ignore
import HeroMap from "../../../Assets/images/Map-2.png";
import CommonService from "../../../Shared/Services/CommonService.tsx";
import { useNavigate, useParams } from "react-router";
import SignIn from "../../../Authentication/SignIn.tsx";
import { ModuleType } from "../../../Shared/Utils/Constants.tsx";

const Home = () => {
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate();
  const dataFetcher = new CommonService();
  const { id } = useParams();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const localResponse: any = {
      Id: localStorage.getItem("user_details_azure_id"),
      Email: localStorage.getItem("user_details_email"),
      DisplayName: localStorage.getItem("user_details_displayName"),
      Token: localStorage.getItem("accessToken"),
    };

    if (localResponse?.Email) {
      try {
        const response = await dataFetcher.getUserByEmail(
          localResponse?.Email
        );
        navigate(`/vce/${response.PermissionInfo?.VceRootFolderId}`);
        setUserData(response);
      } catch (error) {
        return <SignIn />;
      }
    }
  };
  return (
    <div className="pb-2">
      <section className="w-100 home-hero d-flex align-items-center">
        <div className="container-fluid d-flex justify-content-between">
          <div className="d-flex flex-column justify-content-center">
            <div className="text-dark  ">
              <div
                style={{
                  lineHeight: "4rem",
                  fontSize: "4rem",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  textShadow: "3px 2px 5px rgb(255 255 255)",
                }}
              >
                Firbank Global
              </div>
              <div
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  textShadow: "3px 2px 5px rgb(255 255 255)",
                }}
              >
                Making Connections
              </div>
            </div>
            <p
              className="text-dark"
              style={{ fontSize: "1.4rem", fontWeight: "300" }}
            >
              Firbank Transnational Education
            </p>
          </div>
          <div className="d-none d-lg-block">
            <img src={HeroMap} style={{ height: "350px" }}  />
          </div>
        </div>
      </section>

      <FolderContent rootFolderId={id ? id : null} moduleType={ModuleType.VCE} />
    </div>
  );
};

export default Home;

import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryData from "../../../Shared/Utils/countries.json";

const SubjectFormLayout = (props: {
  setFormData;
  formData;
}) => {
  const onChange = (event) => {
    props.setFormData({
      ...props.formData,
      [event.target.id]: {
        ...props.formData[event.target.id],
        value: event.target.value,
      },
    });
  };

  const handleCountryName = (code) => {
    const country = countryData.find((country) => country.code === code);
    const event = {
      target: {
        id: "country",
        value: {
          countryName: country?.name,
          countryCode: code,
        },
      },
    };
    onChange(event);
  };

  return (
    <div>
      <div className="mb-3">
        <label className="col-form-label">School Code</label>
        <input
          type="text"
          id="schoolCode"
          className="form-control"
          value={props?.formData?.schoolCode?.value}
          aria-describedby="passwordHelpInline"
          onChange={onChange}
        />
        {props?.formData?.schoolCode?.isError && (
          <div className="error-message">
            {props?.formData?.schoolCode?.errorMessage} *
          </div>
        )}
      </div>
      <div className="mb-1">
        <label className="col-form-label">Name</label>
        <input
          type="text"
          id="schoolName"
          className="form-control"
          value={props?.formData?.schoolName?.value}
          aria-describedby="passwordHelpInline"
          onChange={onChange}
        />
        {props?.formData?.schoolName?.isError && (
          <div className="error-message">
            {props?.formData?.schoolName?.errorMessage} *
          </div>
        )}
      </div>
      <div className="mb-3">
        <label className="col-form-label">Description</label>
        <textarea
          id="description"
          className="form-control"
          value={props?.formData?.description?.value}
          aria-describedby="passwordHelpInline"
          onChange={onChange}
        />
        {props?.formData?.description?.isError && (
          <div className="error-message">
            {props?.formData?.description?.errorMessage} *
          </div>
        )}
      </div>
      <div className="mb-1">
        <label className="col-form-label">Address</label>
        <input
          type="text"
          id="address"
          className="form-control"
          value={props?.formData?.address?.value}
          aria-describedby="passwordHelpInline"
          onChange={onChange}
        />
        {props?.formData?.address?.isError && (
          <div className="error-message">
            {props?.formData?.address?.errorMessage} *
          </div>
        )}
      </div>
      <div className="mb-3">
        <label className="col-form-label">Email</label>
        <input
          type="text"
          id="email"
          className="form-control"
          value={props?.formData?.email?.value}
          aria-describedby="passwordHelpInline"
          onChange={onChange}
        />
        {props?.formData?.email?.isError && (
          <div className="error-message">
            {props?.formData?.email?.errorMessage} *
          </div>
        )}
      </div>

      <div className="mb-3">
        <label className="col-form-label">Country</label>
        <ReactFlagsSelect
          searchable={true}
          selected={props?.formData?.country?.value?.countryCode}
          onSelect={(code) => {
            handleCountryName(code);
          }}
        />
        {props?.formData?.country?.isError && (
          <div className="error-message">
            {props?.formData?.country?.errorMessage} *
          </div>
        )}
      </div>

      <div className="mb-1">
        <label className="col-form-label">Telephone Number</label>
        <PhoneInput
          country={(props?.formData?.country?.value?.countryCode).toLowerCase()}
          value={props?.formData?.telenumber?.value}
          placeholder="Enter your number"
          onChange={(phone) => {
            const event = {
              target: {
                id: "telenumber",
                value: phone,
              },
            };
            onChange(event);
          }}
        />
        {props?.formData?.telenumber?.isError && (
          <div className="error-message">
            {props?.formData?.telenumber?.errorMessage} *
          </div>
        )}
      </div>
    </div>
  );
};

export default SubjectFormLayout;

import React, { useState } from "react";
// @ts-ignore
import FolderIcon from "../../../Assets/images/Folder/folder-100.svg";
// @ts-ignore
import MoreIcon from "../../../Assets/images/coolicon.svg";
// @ts-ignore
import DownloadIcon from "../../../Assets/images/cloud_download.svg";
// @ts-ignore
import EditIcon from "../../../Assets/images/edit.svg";
// @ts-ignore
import Delete from "../../../Assets/images/crud/trash_full.svg";
// @ts-ignore
import ShareIcon from "../../../Assets/images/share.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { AcceesLevel } from "../../Utils/Constants.tsx";
import Tooltip from "@mui/material/Tooltip";
import { ModuleType } from "../../../Shared/Utils/Constants.tsx";

const Folder = ({
  folderInfo,
  selectedFolder,
  onUpdate,
  editInfo,
  onDeleteCallBack,
  moduleType
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const usertype: any = localStorage.getItem("user-type-id");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDeleteCallBack(folderInfo?.Id, folderInfo?.ParentFolderId);
  };

  return (
    <div className=" bg-light p-1 d-flex flex-row justify-content-center align-items-center ">

      <Tooltip title={folderInfo?.Name} placement="top">  
      <a
        className="text-decoration-none mouse-cursor d-flex flex-row justify-content-start align-items-center"
        onDoubleClick={() => selectedFolder(folderInfo?.Id)}
      >
        <img src={FolderIcon} className="me-2 w-25" alt="" />
        <div className="text-left "    style={{
          
              width:"150px",
              paddingRight:"5px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap", 
            }}>
              
            <span className=" text-dark ">{folderInfo?.Name}</span>
        </div>
      </a>
      </Tooltip>
      <div className="text-end">
        {AcceesLevel.some(
          (item) => moduleType === ModuleType.VCE ? parseInt(usertype) === item.Id && item.AllowTo.includes("ALL") :
            (parseInt(usertype) === item.Id && (item.AllowTo.includes("EDIT_FOLDER") || item.AllowTo.includes("DELETE_FOLDER")))
        ) && (
            <Tooltip title="More" placement="top">
              <a
                href="#"
                id="basic-button"
                className="folder dropdown-toggle px-1"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img src={MoreIcon} alt="" />
              </a>
            </Tooltip>
          )}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {AcceesLevel.some(
          (item) => moduleType === ModuleType.VCE ? parseInt(usertype) === item.Id && item.AllowTo.includes("ALL") :
            parseInt(usertype) === item.Id &&
            item.AllowTo.includes("EDIT_FOLDER")
        ) && (
            <MenuItem
              onClick={() => {
                onUpdate("UPDATE_FOLDER");
                editInfo(folderInfo);
                handleClose();
              }}
            >
              <a className="dropdown-item align-items-center d-flex">
                <img src={EditIcon} alt="" className="me-2" />{" "}
                <span className="small">Edit</span>
              </a>
            </MenuItem>
          )}

        {AcceesLevel.some(
          (item) => moduleType === ModuleType.VCE ? parseInt(usertype) === item.Id && item.AllowTo.includes("ALL") :
            parseInt(usertype) === item.Id &&
            item.AllowTo.includes("DELETE_FOLDER")
        ) && (
            <MenuItem onClick={handleDelete}>
              <a className="dropdown-item align-items-center d-flex">
                <img src={Delete} alt="" className="me-2" />{" "}
                <span className="small text-danger">Delete</span>
              </a>
            </MenuItem>
          )}
      </Menu>
    </div>
  );
};

export default Folder;

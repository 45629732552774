// @ts-ignore
import { VARIABLES } from "../../Utils/Constants.tsx";

export const getRoutingPart = (location, setRoutingPath, path) => {
  const pathname = location.pathname;
  const secondSlashIndex = pathname.indexOf("/", 1);
  const routingPart =
    secondSlashIndex !== -1
      ? pathname.substring(1, secondSlashIndex)
      : pathname.substring(1);
  if (path === VARIABLES.NAV_PATH) {
    switch (routingPart) {
      case "":
        setRoutingPath("Home");
        break;
      case "vce":
          setRoutingPath("Home");
          break;
      case "subjects":
        setRoutingPath("Subjects");
        break;
      case "schools":
        setRoutingPath("Schools");
        break;
      case "users":
        setRoutingPath("Users");
        break;
      default:
        break;
    }
  } else {
    return routingPart;
  }
};

import SubjectService from "../Services/SubjectService.tsx";
import subject_urls from "../../../Shared/Utils/subjects-urls.json";
import { toast } from "react-toastify";
const dataFetcher = new SubjectService();

export const loadSubjects = (setSubjects, setIsProgress) => {
  dataFetcher
    .getAllSubjects()
    .then((result) => {
      const updatedList = result.map((item) => {
        switch (item?.Name) {
          case "Accounting":
            return { ...item, img: subject_urls.accounting };
          case "Business Management":
            return { ...item, img: subject_urls.business_management };
          case "Chemistry":
            return { ...item, img: subject_urls.chemistry };
          case "Chinese First Language":
            return { ...item, img: subject_urls.chinese_first_language };
          case "General Maths":
            return { ...item, img: subject_urls.general_maths };
          case "Mathematics":
            return { ...item, img: subject_urls.mathematics };
          case "Physics":
            return { ...item, img: subject_urls.physics };
          case "Specialist Maths":
            return { ...item, img: subject_urls.specialist_maths };
          case "English as an Additional Language (EAL)":
            return { ...item, img: subject_urls.english_as_an_additional };

          default:
            return { ...item, img: subject_urls.english_as_an_additional };
        }
      });
      setSubjects(updatedList);
      setIsProgress(false);
    })
    .catch((error) => {
      setSubjects([]);
      setIsProgress(false);
      console.log("Subject Error : ", error);
    });
};

export const createSubject = (
  formData,
  setShowModal,
  callbackfunction,
  callbackstatus,
  isProgress
) => {
  const data = {
    Name: formData.subjectName.value,
    Description: formData.description.value,
  };

  isProgress(true);
  dataFetcher
    .createSubject(data)
    .then(() => {
      setShowModal(false);
      callbackfunction(!callbackstatus);
      isProgress(false);
      toast.success("Subject Created");
    })
    .catch((error) => {
      isProgress(false);
      toast.error(error?.response?.data?.Message);
      console.log("Error : ", error);
    });
};

export const updateSubject = (
  selectedSubjectId,
  formData,
  setShowModal,
  setRenderPage,
  renderPage,
  isProgerss
) => {
  const data = {
    Id: selectedSubjectId?.Id,
    Name: formData.subjectName.value,
    Description: formData.description.value,
    RootFolderId: selectedSubjectId?.RootFolderId,
  };

  isProgerss(true);

  dataFetcher
    .updateSubject(data)
    .then(() => {
      setShowModal(false);
      setRenderPage(!renderPage);
      isProgerss(false);
      toast.success("Subject Updated");
    })
    .catch((error) => {
      isProgerss(false);
      toast.error(error?.response?.data?.Message);
      console.log("Update Error : ", error);
    });
};

export const deleteSubject = (
  item,
  setRenderPage,
  renderPage,
  setShowConfirmModal,
  setIsProgress
) => {
  setIsProgress(true);
  dataFetcher
    .deleteSubject(item?.Id)
    .then(() => {
      setRenderPage(!renderPage);
      setIsProgress(false);
      setShowConfirmModal(false);
      toast.success("Subject Deleted");
    })
    .catch((error) => {
      setIsProgress(false);
      toast.error(error?.response?.data?.Message);
      console.log("Delete Error : ", error);
    });
};

import { toast } from "react-toastify";
import { userTypes } from "../../../Shared/Utils/Constants.tsx";
import SchoolService from "../../School/Services/SchoolService.tsx";
import SubjectService from "../../Subject/Services/SubjectService.tsx";
import UserService from "../Services/UserService.tsx";
const dataFetcher = new UserService();
const dataFetcherSchool = new SchoolService();
const dataFetcherSubject = new SubjectService();

export const loadUsers = (setSchools, setIsProgress) => {
  dataFetcher
    .getAllUsers()
    .then((result) => {
      const updatedList = result.map((item) => {
        if (userTypes.hasOwnProperty(item.UserTypeId)) {
          item.UserTypeDisplayName = userTypes[item.UserTypeId];
        }
        return item;
      });
      setSchools(updatedList);
      setIsProgress(false);
    })
    .catch((error) => {
      setSchools([]);
      setIsProgress(false);
      toast.error(error?.response?.data?.Message);
      console.log("Subject Error : ", error);
    });
};

export const createUser = (
  formData,
  selectedDetails,
  setShowModal,
  callbackfunction,
  callbackstatus,
  isProgress
) => {
  const data = {
    FirstName: formData.FirstName.value,
    LastName: formData.LastName.value,
    PersonalEmail: formData.PersonalEmail.value,
    Country: formData.Country.value.countryName,
    CountryCode: formData.Country.value.countryCode,
    UserTypeId: parseInt(formData.UserType.value.UserTypeId),
    TelephoneNumber: formData.PhoneNumber.value,
    PermissionInfo: {
      UserSchools: selectedDetails?.school,
      UserSubjects: selectedDetails?.subject,
    },
  };

  isProgress(true);
  dataFetcher
    .createUser(data)
    .then(() => {
      setShowModal(false);
      callbackfunction(!callbackstatus);
      isProgress(false);
      toast.success("User Created");
    })
    .catch((error) => {
      isProgress(false);
      toast.error(error?.response?.data?.Message);
      console.log("Error : ", error);
    });
};

export const updateUser = (
  selectedSubjectId,
  formData,
  selectedDetails,
  setShowModal,
  setRenderPage,
  renderPage,
  isProgerss
) => {
  const data = {
    Id: selectedSubjectId?.Id,
    FirstName: formData.FirstName.value,
    LastName: formData.LastName.value,
    PersonalEmail: formData.PersonalEmail.value,
    Country: formData.Country.value.countryName,
    CountryCode: formData.Country.value.countryCode,
    UserTypeId: parseInt(formData.UserType.value.UserTypeId),
    TelephoneNumber: formData.PhoneNumber.value,
    PermissionInfo: {
      UserSchools: selectedDetails?.school,
      UserSubjects: selectedDetails?.subject,
    },
  };

  isProgerss(true);

  dataFetcher
    .updateUser(data)
    .then(() => {
      setShowModal(false);
      isProgerss(false);
      setRenderPage(!renderPage);
      toast.success("User Updated");
    })
    .catch((error) => {
      setShowModal(false);
      isProgerss(false);
      toast.error(error?.response?.data?.Message);
      console.log("Update Error : ", error);
    });
};

export const deleteUser = (
  item,
  setRenderPage,
  renderPage,
  setShowConfirmModal,
  setIsProgress
) => {
  setIsProgress(true);
  dataFetcher
    .deleteUser(item?.Id)
    .then(() => {
      setRenderPage(!renderPage);
      setIsProgress(false);
      setShowConfirmModal(false);
      toast.success("User Deleted");
    })
    .catch((error) => {
      setIsProgress(false);
      setShowConfirmModal(false);
      toast.error(error?.response?.data?.Message);
      console.log("Delete Error : ", error);
    });
};

export const getUserType = (setSchools) => {
  dataFetcher
    .getUserTypes()
    .then((result) => {
      setSchools(result);
    })
    .catch((error) => {
      setSchools([]);
      console.log("Subject Error : ", error);
    });
};

export const getAllSchool = async () => {
  const response = await dataFetcherSchool.getAllSchool();
  return response;
};

export const getAllSubject = async () => {
  const response = await dataFetcherSubject.getAllSubjects();
  return response;
};

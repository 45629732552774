import React, { useState, useEffect, useMemo } from "react";
// @ts-expect-error
import ViewIcon from "../../../Assets/images/crud/show.svg";
// @ts-expect-error
import UserIcon from "../../../Assets/images/user.png";
// @ts-expect-error
import UpdateIcon from "../../../Assets/images/crud/edit.svg";
// @ts-expect-error
import DeleteIcon from "../../../Assets/images/crud/trash_full.svg";
import { USER_FORM_DATA, userTypes } from "../../../Shared/Utils/Constants.tsx";
import { Link as RouterLink } from "react-router-dom";
import { deleteUser, loadUsers, updateUser } from "./UserFunction.tsx";
import ConfirmationModal from "../../../Component/ConfirmationModal.tsx";
import CustomModal from "../../../Component/CustomModal.tsx";
import UserFormLayout from "../Layouts/UserFormLayout.tsx";
import { checkValidation } from "../../../Shared/Components/Utilities/Validator.tsx";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { getAllSchool, getAllSubject } from "./UserFunction.tsx";
import DataTable from "react-data-table-component";

const UserList = ({ render }) => {
  const [users, setUsers] = useState<any>([]);
  const [renderPage, setRenderPage] = useState(false);
  const [isProgerss, setIsProgress] = useState(false);
  const [isFilterOption, setIsFilterOption] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(USER_FORM_DATA);
  const [selectedSubjectIds, setSelectedSubjectIds] = useState({
    Id: "",
    RootFolderId: "",
  });
  const [selectedSubject, setSelectedSubject] = useState<any>([]);
  const [selectedSchool, setSelectedSchool] = useState<any>([]);
  const [subjects, setSubjects] = useState([]);
  const [schools, setSchools] = useState([]);
  const [state, setState] = useState({
    title: "",
    isView: false,
  });

  useEffect(() => {
    setIsProgress(true);
    loadUsers(setUsers, setIsProgress);
  }, [render, renderPage]);

  useEffect(() => {
    execute();
  }, []);

  async function execute() {
    setSubjects(await getAllSubject());
    setSchools(await getAllSchool());
  }

  const handleOnClick = (item) => {
    setShowModal(true);
    setSelectedSubjectIds({
      Id: item?.Id,
      RootFolderId: item?.RootFolderId,
    });

    const userTypeName = Object.fromEntries(
      Object.entries(userTypes).filter(
        ([key]) => parseInt(key) === item?.UserTypeId
      )
    );

    setFormData({
      FirstName: {
        id: formData?.FirstName?.id,
        isRequired: false,
        isDisabled: false,
        value: item?.FirstName,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
      LastName: {
        id: formData?.LastName?.id,
        isRequired: false,
        isDisabled: false,
        value: item?.LastName,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
      PersonalEmail: {
        id: formData?.PersonalEmail?.id,
        isRequired: false,
        isDisabled: false,
        value: item?.PersonalEmail,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
      Country: {
        id: formData?.Country?.id,
        value: {
          countryCode: item?.CountryCode,
          countryName: item?.Country,
        },
        isError: false,
        errorMessage: "",
      },

      UserType: {
        id: formData?.UserType?.id,
        value: {
          UserTypeId: item?.UserTypeId,
          UserTypeValue: userTypeName[item?.UserTypeId],
        },
        isError: false,
        errorMessage: "",
      },

      PhoneNumber: {
        id: "PhoneNumber",
        isRequired: true,
        value: item?.TelephoneNumber,
        isDisabled: false,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
    });
    setSelectedSchool(item?.PermissionInfo?.UserSchools);
    setSelectedSubject(item?.PermissionInfo?.UserSubjects);
  };

  const handleDelete = (item) => {
    setSelectedSubjectIds({
      Id: item?.Id,
      RootFolderId: item?.RootFolderId,
    });
    setShowConfirmModal(true);
  };

  const handleUpdate = () => {
    const response = checkValidation(formData, setFormData);
    const selectedDetails = {
      subject: selectedSubject,
      school: selectedSchool,
    };
    if (response) {
      updateUser(
        selectedSubjectIds,
        formData,
        selectedDetails,
        setShowModal,
        setRenderPage,
        renderPage,
        setIsProgress
      );
    }
  };

  const handleViewBtn = () => {
    setState({
      title: "Profile Details",
      isView: true,
    });
    setShowModal(true);
  };

  const handleUpdateBtn = () => {
    setState({
      title: "Update User",
      isView: false,
    });
    setShowModal(true);
  };

  const columns = [
    {
      name: "FirstName",
      selector: (row) => row.FirstName,
      sortable: true,
      width : '180px'
    },
    {
      name: "LastName",
      selector: (row) => row.LastName,
      sortable: true,
      width : '180px'
    },
    {
      name: "Personal Email",
      selector: (row) => row.PersonalEmail,
      sortable: true,
      width : '250px'
    },
    {
      name: "Portal Email",
      selector: (row) => row.PortalEmail,
      sortable: true,
      width : '250px'
    },
    {
      name: "Country",
      selector: (row) => row.Country,
      sortable: true,
    },
    {
      name: "School",
      selector: (row) =>
        row?.PermissionInfo?.UserSchools?.length >= 0
          ? row?.PermissionInfo?.UserSchools[0]?.Name
          : null,
      sortable: true,
      width : '320px'
    },
    {
      name: "User Type",
      selector: (row) => row.UserTypeDisplayName,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (item) => (
        <div className="p-2 align-items-center d-flex justify-content-start justify-content-lg-center d-flex align-items-center">
          <Tooltip title="Update" placement="top">
            <a
              className="text-decoration-none"
              onClick={() => {
                handleOnClick(item);
                handleUpdateBtn();
              }}
            >
              {" "}
              <img src={UpdateIcon} alt="" className="img-fluid" />{" "}
            </a>
          </Tooltip>
          <RouterLink className="text-decoration-none" to={`/users`}>
            <Tooltip title="View" placement="top">
              <a
                className="text-decoration-none"
                onClick={() => {
                  console.log("View button clicked");
                  handleOnClick(item);
                  handleViewBtn();
                }}
              >
                {" "}
                <img src={ViewIcon} alt="" className="img-fluid mx-3" />{" "}
              </a>
            </Tooltip>
          </RouterLink>

          <Tooltip title="Delete" placement="top">
            <a className="text-decoration-none">
              {" "}
              <img
                src={DeleteIcon}
                alt=""
                className="img-fluid"
                onClick={() => handleDelete(item)}
              />{" "}
            </a>
          </Tooltip>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleFilterFirstName = (event) => {
    const filteredData = users.filter((row) => {
      return row.FirstName.toLowerCase().includes(
        event.target.value.toLowerCase()
      );
    });

    if (event.target.value === "") {
      loadUsers(setUsers, setIsProgress);
      return;
    }
    setUsers(filteredData);
  };

  const handleFilterLastName = (event) => {
    const filteredData = users.filter((row) => {
      return row.LastName.toLowerCase().includes(
        event.target.value.toLowerCase()
      );
    });

    if (event.target.value === "") {
      loadUsers(setUsers, setIsProgress);
      return;
    }
    setUsers(filteredData);
  };

  const handleFilterCountry = (event) => {
    const filteredData = users.filter((row) => {
      return row.Country.toLowerCase().includes(
        event.target.value.toLowerCase()
      );
    });

    if (event.target.value === "") {
      loadUsers(setUsers, setIsProgress);
      return;
    }
    setUsers(filteredData);
  };

  // row?.PermissionInfo?.UserSchools?.length >= 0 ? row?.PermissionInfo?.UserSchools[0]?.Name : null
  const handleFilterSchool = (event) => {
    const filteredData = users.filter((row) => {
      return row?.PermissionInfo?.UserSchools?.length >= 0
        ? row?.PermissionInfo?.UserSchools[0]?.Name?.toLowerCase()?.includes(
            event?.target?.value?.toLowerCase()
          )
        : null;
      // return row.Country.toLowerCase().includes(
      //   event.target.value.toLowerCase()
      // );
    });

    if (event.target.value === "") {
      loadUsers(setUsers, setIsProgress);
      return;
    }
    setUsers(filteredData);
  };

  const handleFilterUserType = (event) => {
    const filteredData = users.filter((row) => {
      return row.UserTypeDisplayName.toLowerCase().includes(
        event.target.value.toLowerCase()
      );
    });

    if (event.target.value === "") {
      loadUsers(setUsers, setIsProgress);
      return;
    }
    setUsers(filteredData);
  };

  const enableFilters = () => {
    setIsFilterOption(!isFilterOption);
    if (isFilterOption) {
      loadUsers(setUsers, setIsProgress);
    }
  };
  return (
    <div className="main">
      <section className="container-fluid">
        <button
          type="button"
          className={`btn btn-sm ${
            isFilterOption ? "btn-color-close" : "btn-color"
          } px-3`}
          onClick={() => enableFilters()}
        >
          {isFilterOption ? "Close / Clear" : "Filters"}
        </button>

        {isFilterOption && (
          <div className="row">
            <div className="col col-lg-2 col-md-2 col-sm-6 col-12">
              <label className="col-form-label">First Name</label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by First Name"
                onChange={handleFilterFirstName}
              />
            </div>
            <div className="col col-lg-2 col-md-2 col-sm-6 col-12">
              <label className="col-form-label">Last Name</label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by Last Name"
                onChange={handleFilterLastName}
              />
            </div>
            <div className="col col-lg-2 col-md-2 col-sm-6 col-12">
              <label className="col-form-label">Country</label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by Country"
                onChange={handleFilterCountry}
              />
            </div>
            <div className="col col-lg-2 col-md-2 col-sm-6 col-12">
              <label className="col-form-label">School</label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by Country"
                onChange={handleFilterSchool}
              />
            </div>
            <div className="col col-lg-2 col-md-2 col-sm-6 col-12">
              <label className="col-form-label">User Type</label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by User Type"
                onChange={handleFilterUserType}
              />
            </div>
          </div>
        )}

        <hr />

        <DataTable
          columns={columns}
          data={users}
          fixedHeader={true}
          pagination={true}
          highlightOnHover={true}
          responsive={true}
          pointerOnHover={true}
        ></DataTable>
      </section>

      <CustomModal
        IsOpen={showModal}
        IsClose={setShowModal}
        Title={state.title}
        isProgress={isProgerss}
        onSuccessCallBack={() => handleUpdate()}
        element={
          <UserFormLayout
            setFormData={setFormData}
            formData={formData}
            subjects={subjects}
            schools={schools}
            selectedSubject={selectedSubject}
            selectedSchool={selectedSchool}
            setSelectedSubject={setSelectedSubject}
            setSelectedSchool={setSelectedSchool}
            isUpdate={true}
            isView={state.isView}
          />
        }
        isDisabled={state.isView}
      />

      <ConfirmationModal
        IsOpen={showConfirmModal}
        IsClose={setShowConfirmModal}
        Title={"Delete User"}
        isProgress={isProgerss}
        element={
          "Do you want to delete this ? If you delete this user, The user will be delete from the AzureAD !"
        }
        onSuccessCallBack={() =>
          deleteUser(
            selectedSubjectIds,
            setRenderPage,
            renderPage,
            setShowConfirmModal,
            setIsProgress
          )
        }
      />
    </div>
  );
};

export default UserList;

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { refreshAcquireToken } from "./AcquireToken.tsx";

export default class ServiceClient {
  static async CallApi(
    apiCall: (config: AxiosRequestConfig) => Promise<AxiosResponse<any>>
  ): Promise<any> {
    try {
      const accessToken = await refreshAcquireToken();
      const bearerToken = `Bearer ${accessToken}`;

      const headers = {
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_HEADER_KEY || "",
        Authorization: bearerToken,
        User: localStorage.getItem("user_details_email") || "",
      };

      const axiosConfig: AxiosRequestConfig = { headers };

      const response: AxiosResponse = await apiCall(axiosConfig);

      return response;
    } catch (error) {
      console.error("Error in API call:", error.message);
      throw error;
    }
  }

  static async POST(url: string, data: object) {
    const response = await ServiceClient.CallApi(
      async (config) => await axios.post(url, data, config)
    );

    return response;
  }

  static async PUT(url: string, data: object) {
    const response = await ServiceClient.CallApi(
      async (config) => await axios.put(url, data, config)
    );

    return response;
  }

  static async DELETE(url: string) {
    const response = await ServiceClient.CallApi(
      async (config) => await axios.delete(url, config)
    );

    return response;
  }

  static async GET(url: string, params: any) {
    let baseUrl = url;
    if (params) baseUrl = url + `${params}`;

    const response = await ServiceClient.CallApi(
      async (config) => await axios.get(baseUrl, config)
    );

    return response.data;
  }

  static async GET_DATA_BY_TOKEN(url: string, params: any, token: any) {
    let baseUrl = url;
    if (params) baseUrl = url + `${params}`;
    try {
      const response = await axios.get(baseUrl, {
        headers: {
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_HEADER_KEY,
          Authorization: token,
          User: localStorage.getItem("user_details_email")
            ? localStorage.getItem("user_details_email")
            : params,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error GET Request:", error.message);
      throw error;
    }
  }

  static async DOWNLOAD(
    requestUrl: string,
    contentType: any,
    details: any,
    handleDetails: any
  ) {
    try {
      handleDetails({
        count: 0,
        isProgressBar: true,
        filename: details?.FileMetaData?.OriginalFileName,
        isUpload: false,
        isEnabled: true,
      });
      const response = await axios.get(requestUrl, {
        responseType: "blob",
        onDownloadProgress(progressEvent: any) {
          const progress = (progressEvent?.progress * 100).toFixed(2);
          handleDetails({
            count: progress,
            isProgressBar: true,
            filename: details?.FileMetaData?.OriginalFileName,
            isUpload: false,
            isEnabled: true,
          });
        },
      });
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = details?.FileMetaData?.OriginalFileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      handleDetails({
        count: 0,
        isProgressBar: true,
        filename: details?.FileMetaData?.OriginalFileName,
        isUpload: false,
        isEnabled: false,
      });
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }
}

import React, { useEffect, useState } from "react";
import "./TopNavBar.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Link, useLocation } from "react-router-dom";
// @ts-ignore
import LogoImg from "../../Assets/images/Logo-H.svg";
// @ts-ignore
import HomeIcon from "../../Assets/images/home_work.svg";
// @ts-ignore
import SubjectIcon from "../../Assets/images/hov_menu_book.svg";
// @ts-ignore
import SchoolIcon from "../../Assets/images/hov_domain.svg";
// @ts-ignore
import UserIcon from "../../Assets/images/hov_people.svg";
// @ts-ignore
import ProfileIcon from "../../Assets/images/Profile.svg";
// @ts-ignore
import SignOut from "../../Assets/images/login.svg";
// @ts-ignore
import { getRoutingPart } from "../../Shared/Components/Utilities/Functions.tsx";
// @ts-ignore
import { AcceesLevel, VARIABLES } from "../../Shared/Utils/Constants.tsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery";
import "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import SignOutButton from "../../Authentication/SignOut.tsx";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const TopNavBar = ({ userInfo }) => {
  const [navBreadCrumb, setnavBreadCrumb] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const displayName = localStorage.getItem("user_details_displayName");
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    getRoutingPart(location, setnavBreadCrumb, VARIABLES.NAV_PATH);
  }, [pathname]);

  const handleClick = (event) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {
        // NavBG
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark  shadow-sm py-1">
          <div className="container-fluid">
            <a
              className="navbar-brand py-0 pb-1"
              href="https://firbank.education/"
              target="_blank"
            >
              <img src={LogoImg} className="img-fluid" alt="" />
            </a>

            <div className="d-flex justify-content-end align-items-center">
              {/* burger menu */}
              <button
                className="navbar-toggler  me-3 text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon  text-white"></span>
              </button>
              {/* mobile user profile */}
              <div className="d-block d-sm-block d-md-block d-lg-none">
                <ul className="navbar-nav ">
                  <a
                    href="#"
                    id="basic-button"
                    className="nav-item dropdown dropdown-toggle text-white text-decoration-none"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(event) => handleClick(event)}
                  >
                    <img src={ProfileIcon} alt="" className="me-2" />
                    <span className="d-none">{displayName}</span>
                  </a>
                </ul>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  style={{ left: "-50px", top: "35px" }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem>
                    <SignOutButton />
                  </MenuItem>
                </Menu></div>

            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <ul className="navbar-nav me-auto ms-3">
                      <li className="nav-item ms-3">
                        <Link
                          className="nav-link active d-flex align-items-center ps-0 text-white hover-img"
                          to="/vce"
                        >
                          <img src={HomeIcon} alt="" />
                          <span className="ms-2">Home</span>
                        </Link>
                      </li>
                      {AcceesLevel.some(
                        (item) =>
                          userInfo?.UserTypeId === item.Id &&
                          item.AllowTo.includes("SUBJ")
                      ) ? (
                        <li className="nav-item  ms-3">
                          <Link
                            className="nav-link text-white d-flex align-items-center hover-img"
                            to="/subjects"
                          >
                            <img src={SubjectIcon} alt="" className="icobook" />
                            <span className="ms-2">Subjects</span>
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}

                      {AcceesLevel.some(
                        (item) =>
                          userInfo?.UserTypeId === item.Id &&
                          item.AllowTo.includes("SCHL")
                      ) ? (
                        <li className="nav-item ms-3 ">
                          <Link
                            className="nav-link text-white d-flex align-items-center hover-img"
                            to="/schools"
                          >
                            <img src={SchoolIcon} alt="" />
                            <span className="ms-2">Schools</span>
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}

                      {AcceesLevel.some(
                        (item) =>
                          userInfo?.UserTypeId === item.Id &&
                          item.AllowTo.includes("ALL")
                      ) ? (
                        <li className="nav-item  ms-3">
                          <Link
                            className="nav-link text-white d-flex align-items-center hover-img"
                            to="/users"
                          >
                            <img src={UserIcon} alt="" />
                            <span className="ms-2">Users</span>
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                    <div className="d-none d-sm-none d-md-none d-lg-block">
                      <ul className="navbar-nav ">
                        <a
                          href="#"
                          id="basic-button"
                          className="nav-item dropdown dropdown-toggle text-white text-decoration-none"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(event) => handleClick(event)}
                        >
                          <img src={ProfileIcon} alt="" className="me-2" />
                          <span>{displayName}</span>
                        </a>
                      </ul>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        style={{ left: "-50px", top: "35px" }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem>
                          <SignOutButton />
                        </MenuItem>
                      </Menu></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      }
    </div>
  );
};

export default TopNavBar;

import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
// @ts-ignore
import { loginRequest } from "./authConfig.js";
import { useNavigate } from "react-router";

const SignIn = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  navigate('/login');
  
  useEffect(() => {
    instance
      .loginRedirect(loginRequest)
      .then((result) => {})
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return <div>Sign in using Redirect</div>;
};

export default SignIn;

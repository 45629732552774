import { UserDataFetchingInterface } from "../../../Interfaces/UserDataFetchingInterface";

export class UserDataFetcher implements UserDataFetchingInterface {
    createUser(data: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    getAllUsers(): Promise<any> {
        throw new Error("Method not implemented.");
    }
    getUser(parentId: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    getUserTypes(): Promise<any> {
        throw new Error("Method not implemented.");
    }
    deleteUser(userId: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    updateUser(data: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
  
}
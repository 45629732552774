import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import countryData from "../../../Shared/Utils/countries.json";
import Multiselect from "multiselect-react-dropdown";
import { getUserType } from "../Components/UserFunction.tsx";
import PhoneInput from "react-phone-input-2";

const SubjectFormLayout = (props: {
  setFormData;
  formData;
  subjects;
  schools;
  selectedSubject;
  selectedSchool;
  setSelectedSubject;
  setSelectedSchool;
  isUpdate;
  isView;
}) => {
  const [userType, setUserType] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectUserType, setSelectUserType] = useState("");
  let listSubject: any = [];
  let listSchool: any = [];

  useEffect(() => {
    getUserType(setUserType);
    permissionHandler(props?.formData?.UserType?.value?.UserTypeValue);
  }, []);

  useEffect(() => {
    if (!props.isUpdate) {
      props.setSelectedSubject([]);
      props.setSelectedSchool([]);
    }
  }, [props.isUpdate]);

  const onChange = (event) => {
    props.setFormData({
      ...props.formData,
      [event.target.id]: {
        ...props.formData[event.target.id],
        value: event.target.value,
      },
    });
  };

  const handleCountryName = (code) => {
    const country = countryData.find((country) => country.code === code);
    const event = {
      target: {
        id: "Country",
        value: {
          countryName: country?.name,
          countryCode: code,
        },
      },
    };
    onChange(event);
  };

  const handleCheckSubject = (item, event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      listSubject.push({
        Id: item?.Id,
        Name: item?.Name,
      });
    } else {
      props.setSelectedSubject([]);
      listSubject = props?.selectedSubject?.filter(
        (selectedSubject) => selectedSubject.Id !== item.Id
      );
    }

    props.setSelectedSubject((previousSubject) => [
      ...previousSubject,
      ...listSubject,
    ]);
  };

  const handleCheckSchool = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      listSchool.push({
        Id: item?.Id,
        Code: item?.Code,
        Name: item?.Name,
      });
    } else {
      props.setSelectedSchool([]);
      listSchool = props?.selectedSchool?.filter(
        (selectedSchool) => selectedSchool.Id !== item.Id
      );
    }

    props.setSelectedSchool((previousSchool) => [
      ...previousSchool,
      ...listSchool,
    ]);
  };

  const onSelectSchool = (selectedList) => {
    props.setSelectedSchool(selectedList);
  };

  const onSelectSubject = (selectedList) => {
    props.setSelectedSubject(selectedList);
  };

  const onRemoveSubject = (selectedList) => {
    props.setSelectedSubject(selectedList);
  };

  const permissionHandler = (item) => {
    switch (item) {
      case "Admin":
        setSelectUserType("VISIBLE");
        break;
      case "Co-Ordinator":
        setSelectUserType("EXCP_SUB");
        break;
      case "Mentor":
        setSelectUserType("EXCP_SCL");
        break;
      case "Teacher":
        setSelectUserType("ALL");
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <div className="mb-1">
        <label className="col-form-label">First Name</label>
        <input
          type="text"
          id="FirstName"
          disabled={props?.formData?.FirstName?.isDisabled || props.isView}
          value={props?.formData?.FirstName?.value}
          className="form-control"
          aria-describedby="passwordHelpInline"
          onChange={onChange}
        />
        {props?.formData?.FirstName?.isError && (
          <div className="error-message">
            {props?.formData?.FirstName?.errorMessage} *
          </div>
        )}
      </div>
      <div className="mb-1">
        <label className="col-form-label">Last Name</label>
        <input
          type="text"
          id="LastName"
          disabled={props?.formData?.LastName?.isDisabled || props.isView}
          value={props?.formData?.LastName?.value}
          className="form-control"
          aria-describedby="passwordHelpInline"
          onChange={onChange}
        />
        {props?.formData?.LastName?.isError && (
          <div className="error-message">
            {props?.formData?.LastName?.errorMessage} *
          </div>
        )}
      </div>
      <div className="mb-1">
        <label className="col-form-label">Personal Email</label>
        <input
          type="email"
          id="PersonalEmail"
          disabled={props.isView}
          value={props?.formData?.PersonalEmail?.value}
          className="form-control"
          aria-describedby="passwordHelpInline"
          onChange={onChange}
        />
        {props?.formData?.PersonalEmail?.isError && (
          <div className="error-message">
            {props?.formData?.PersonalEmail?.errorMessage} *
          </div>
        )}
      </div>

      <div className="mb-1">
        <label className="col-form-label">Country</label>
        <ReactFlagsSelect
          searchable={true}
          disabled={props.isView}
          selected={props?.formData?.Country?.value?.countryCode}
          onSelect={(code) => {
            handleCountryName(code);
          }}
        />
        {props?.formData?.Country?.isError && (
          <div className="error-message">
            {props?.formData?.Country?.errorMessage} *
          </div>
        )}
      </div>

      <div className="mb-1">
        <label className="col-form-label">Telephone Number</label>
        <PhoneInput
          country={(props?.formData?.Country?.value?.countryCode).toLowerCase()}
          value={props?.formData?.PhoneNumber?.value}
          placeholder="Enter your number"
          onChange={(phone) => {
            const event = {
              target: {
                id: "PhoneNumber",
                value: phone,
              },
            };
            onChange(event);
          }}
        />
        {props?.formData?.PhoneNumber?.isError && (
          <div className="error-message">
            {props?.formData?.PhoneNumber?.errorMessage} *
          </div>
        )}
      </div>

      <div className="mb-1">
        <label className="col-form-label">User Type</label>
        <select
          id="UserType"
          disabled={props.isView}
          value={props?.formData?.UserType?.value?.UserTypeValue}
          className="form-control"
          aria-describedby="passwordHelpInline"
          onChange={(event) => {
            permissionHandler(event?.target?.value);
            props.setSelectedSchool([]);
            props.setSelectedSubject([]);
            const obj = {
              target: {
                id: "UserType",
                value: {
                  UserTypeId:
                    event?.target?.selectedOptions[0].getAttribute("id"),
                  UserTypeValue: event?.target?.value,
                },
              },
            };
            onChange(obj);
          }}
        >
          <option value="" disabled hidden>
            Select a User Type
          </option>
          {userType.map((item, index) => {
            return (
              <>
                <option key={index} id={item?.Id} value={item?.DisplayName}>
                  {item?.DisplayName}
                </option>
              </>
            );
          })}
        </select>
        {props?.formData?.UserType?.isError && (
          <div className="error-message">
            {props?.formData?.UserType?.errorMessage} *
          </div>
        )}
      </div>

      {selectUserType === "ALL" ? (
        <>
          <div className="mb-1">
            <label className="col-form-label">School</label>
            <select
              value={
                props?.selectedSchool && props?.selectedSchool[0]?.Name
                  ? props?.selectedSchool[0]?.Name
                  : ""
              }
              disabled={props.isView}
              className="form-control"
              onChange={(event) => {
                const selectedOption = event.target.selectedOptions[0];
                const id = selectedOption.getAttribute("id");
                const value = selectedOption.value;
                const obj = [
                  {
                    Name: value,
                    Id: id,
                  },
                ];
                onSelectSchool(obj);
              }}
            >
              <option value="" hidden>
                Select a school
              </option>
              {props?.schools.map((school, index) => (
                <option key={index} id={school?.Id} value={school?.Name}>
                  {school?.Name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-1">
            <label className="col-form-label">Subjects</label>
            <Multiselect
              options={props?.subjects}
              disable={props.isView}
              displayValue="Name"
              placeholder={
                props?.selectedSubject && props?.selectedSubject?.length >= 1
                  ? ""
                  : "Select subjects"
              }
              showArrow={true}
              avoidHighlightFirstOption={true}
              onSelect={onSelectSubject}
              onRemove={onRemoveSubject}
              selectedValues={props?.selectedSubject}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {selectUserType === "EXCP_SUB" ? (
        <>
          <div className="mb-1">
            <label className="col-form-label">School</label>
            <select
              value={
                props?.selectedSchool && props?.selectedSchool[0]?.Name
                  ? props?.selectedSchool[0]?.Name
                  : ""
              }
              disabled={props.isView}
              className="form-control"
              onChange={(event) => {
                const selectedOption = event.target.selectedOptions[0];
                const id = selectedOption.getAttribute("id");
                const value = selectedOption.value;
                const obj = [
                  {
                    Name: value,
                    Id: id,
                  },
                ];
                onSelectSchool(obj);
              }}
            >
              <option value="" hidden>
                Select a school
              </option>
              {props?.schools.map((school, index) => (
                <option key={index} id={school?.Id} value={school?.Name}>
                  {school?.Name}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : (
        <></>
      )}

      {selectUserType === "EXCP_SCL" ? (
        <>
          <div className="mb-1">
            <label className="col-form-label">Subjects</label>
            <Multiselect
              options={props?.subjects}
              disable={props.isView}
              displayValue="Name"
              placeholder={
                props?.selectedSubject && props?.selectedSubject?.length >= 1
                  ? ""
                  : "Select subjects"
              }
              showArrow={true}
              avoidHighlightFirstOption={true}
              onSelect={onSelectSubject}
              onRemove={onRemoveSubject}
              selectedValues={props?.selectedSubject}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {/* <div className="mb-1">
        <label className="col-form-label">School</label>
        <Multiselect
          options={props?.schools}
          displayValue="Name"
          placeholder={
            props?.selectedSchool && props?.selectedSchool?.length >= 1
              ? ""
              : "Select a school"
          }
          showArrow={true}
          avoidHighlightFirstOption={true}
          selectionLimit={1}
          onSelect={onSelectSchool}
          onRemove={onRemoveSchool}
          selectedValues={props?.selectedSchool}
        />
      </div> */}
    </div>
  );
};

export default SubjectFormLayout;

// @ts-ignore
import { SubjectDataFetchingInterface } from '../../../Interfaces/SubjectDataFetchingInterface.tsx';

export class SubjectDataFetcher implements SubjectDataFetchingInterface {
    createSubject(data: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    getAllSubjects(): Promise<any> {
        throw new Error('Method not implemented.');
    }
    getSubject(parentId: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    updateSubject(data: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    deleteSubject(subjectId: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
  
}
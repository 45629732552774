import {config} from "../../../Shared/Utils/config.tsx";
import ServiceClient from "../../../Shared/Services/ServiceClient.tsx";
import { toast } from "react-toastify";
import { UserDataFetcher } from "./UserDataFetcher.tsx";

export default class SubjectService extends UserDataFetcher {
  async createUser(data: any): Promise<any> {
    const response = await ServiceClient.POST(
      config.users.BASE_URL + config.users.END_POINTS.CREATE_USER,
      data
    );

    return response;
  }

  async getAllUsers(): Promise<any> {
    const response = await ServiceClient.GET(
      config.users.BASE_URL + config.users.END_POINTS.GET_ALL_USERS,
      null
    );

    return response;
  }

  async getUser(parentId: any): Promise<any> {
    const response = ServiceClient.GET(
      config.users.BASE_URL + config.users.END_POINTS.GET_USER,
      parentId
    );
    const result = await response;
    if(result.code){
      toast.error("Getting User Failed");
    }
    return response;
  }

  async getUserTypes(): Promise<any> {
    const response = await ServiceClient.GET(
      config.users.BASE_URL + config.users.END_POINTS.GET_ALL_USER_TYPE, null);

    return response;
  }

  async updateUser(data: any): Promise<any> {
    const response = await ServiceClient.PUT(
      config.users.BASE_URL + config.users.END_POINTS.UPDATE_USER,
      data
    );

    return response;
  }

  async deleteUser(userId: any): Promise<any> {
    const response = await ServiceClient.DELETE(config.users.BASE_URL + config.users.END_POINTS.DELETE_USER + `/${userId}`);
    const result = await response;  
    if(result.code){
      toast.error(result?.response?.data?.Message);
    }else {
      toast.success("User Deleted")
    }
    return response;
  }
}

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../Assets/Global.css";
import useMediaQuery from "@mui/material/useMediaQuery";

// @ts-ignore
import closeicon from "../Assets/images/closeicon.svg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { height } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  width: "50%",
  maxHeight: "calc(100vh - 150px)",
  overflowY: "auto",
  p: 3,
};

const CustomModal = (props: {
  IsOpen;
  IsClose;
  Title;
  onSuccessCallBack;
  element;
  isProgress;
  isDisabled;
}) => {

  const isXSmScreen = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const isSmScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const isLgScreen = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  useEffect(() => {
    if (isSmScreen || isXSmScreen) {
      console.log("SM breakpoint triggered");
      style.width = "100%";
    } else if (isMdScreen) {
      console.log("MD breakpoint triggered");
      style.width = "100%";
    } else if (isLgScreen) {
      console.log("LG breakpoint triggered");
      style.width = "100%";
    } else {
      console.log("Default breakpoint triggered");
      style.width = "50%";
    }
  
    console.log("Updated width:", style.width);
  }, [isSmScreen, isMdScreen, isLgScreen, isXSmScreen]);
  
  return (    
    <Modal
      open={props.IsOpen}
      onClose={props.IsClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {props.isProgress && (
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="primary" size={50} />
          </Backdrop>
        )}

        <div className="modal-content">
          <div className="modal-header  border-bottom pb-2">
            <Typography id="modal-modal-title" variant="h5" component="h2">
              {props.Title}
            </Typography>
            <a href="#" onClick={() => props.IsClose(false)}>
              <img src={closeicon} alt="" />
            </a>
          </div>

          <div className="modal-body " style={{maxHeight:"calc(100vh - 300px)", overflowY:"scroll",paddingRight:"10px"}}>
            <div>{props.element}</div>
          </div> 
            
        </div>
        <div className="modal-footer pt-3" style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "1px" }}>
              <button 
                type="button"                
                className="btn btn-sm btn-outline-secondary me-2 px-3"                
                onClick={() => props.IsClose(false)}>
                Close
              </button>
              {props.isDisabled ? (
                <></>
              ) : (
                <button
                  disabled={props.isProgress}
                  type="button"
                  className="btn btn-sm btn-color px-3"
                  onClick={props.onSuccessCallBack}>
                  Save
                </button>
              )}
        </div>
      </Box>
    </Modal>    
  );
};

export default CustomModal;

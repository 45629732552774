// @ts-ignore
import { toast } from "react-toastify";
import SubjectService from "../Services/SchoolService.tsx";
const dataFetcher = new SubjectService();

export const loadSchool = (setSchool, setIsProgress) => {
  dataFetcher
    .getAllSchool()
    .then((result) => {
      setSchool(result);
      setIsProgress(false);
    })
    .catch((error) => {
      setSchool([]);
      setIsProgress(false);
      console.log("School Error : ", error);
    });
};

export const createSchool = (
  formData,
  setShowModal,
  callbackfunction,
  callbackstatus,
  isProgerss
) => {
  isProgerss(true);
  const data = {
    Code: formData.schoolCode.value,
    Name: formData.schoolName.value,
    Description: formData.description.value,
    Address: formData.address.value,
    Email: formData.email.value,
    TelephoneNumber: formData.telenumber.value,
    CountryCode: formData.country.value.countryCode,
    Country: formData.country.value.countryName,
  };

  dataFetcher
    .createSchool(data)
    .then(() => {
      setShowModal(false);
      isProgerss(false);
      callbackfunction(!callbackstatus);
      toast.success("School Created");
    })
    .catch((error) => {
      isProgerss(false);
      toast.error(error?.response?.data?.Message);
      console.log("Error : ", error);
    });
};

export const updateSchool = (
  selectedSubjectId,
  formData,
  setShowModal,
  setRenderPage,
  renderPage,
  isProgerss
) => {
  isProgerss(true);
  const data = {
    Id: selectedSubjectId?.Id,
    Code: formData.schoolCode.value,
    Name: formData.schoolName.value,
    Description: formData.description.value,
    Address: formData.address.value,
    Email: formData.email.value,
    TelephoneNumber: formData.telenumber.value,
    CountryCode: formData.country.value.countryCode,
    Country: formData.country.value.countryName,
    RootFolderId: selectedSubjectId?.RootFolderId,
  };

  dataFetcher
    .updateSchool(data)
    .then(() => {
      setShowModal(false);
      isProgerss(false);
      setRenderPage(!renderPage);
      toast.success("School Updated");
    })
    .catch((error) => {
      isProgerss(false);
      toast.error(error?.response?.data?.Message);
      console.log("Update Error : ", error);
    });
};

export const deleteSchool = (
  item,
  setRenderPage,
  renderPage,
  setShowConfirmModal,
  setIsProgress
) => {
  setIsProgress(true);
  dataFetcher
    .deleteSchool(item?.Id)
    .then(() => {
      setRenderPage(!renderPage);
      setIsProgress(false);
      setShowConfirmModal(false);
      toast.success("School Deleted");
    })
    .catch((error) => {
      setIsProgress(false);
      toast.error(error?.response?.data?.Message);
      console.log("Delete Error : ", error);
    });
};

import React, { useState, useEffect } from "react";
import { useUploaderContext } from "./ProgressBarProvider.tsx";

const Progressbar = () => {
  const { details } = useUploaderContext();

  useEffect(() => {
  }, [details.count]);
  return (
    <>
      {details.isEnabled ? (
        <>
          {details.isUpload ? (
            <>
              {details.isProgressBar ? (
                <>
                  <div className="background-modal">
                    <p style={{ fontSize: "14px" }}>{details.filename}</p>
                    <p style={{ fontSize: "12px" }}>
                      {details.count}%{" "}
                      {details.count === 100 ? `Uploaded` : `Uploading`}
                      <span className="pull-right">Please wait...</span>
                    </p>
                    <div
                      className="progress progress-striped active"
                      style={{ width: "auto" }}
                    >
                      <div
                        className="bar"
                        style={{
                          width: `${details.count}%`,
                          backgroundColor: "blue",
                        }}
                      ></div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {details.isProgressBar ? (
                <>
                  <div className="background-modal">
                    <p style={{ fontSize: "14px" }}>{details.filename}</p>
                    <p style={{ fontSize: "12px" }}>
                      {details.count}%{" "}
                      {details.count === 100 ? `Downloaded` : `Downloading`}
                      <span className="pull-right">Please wait...</span>
                    </p>
                    <div
                      className="progress progress-striped active"
                      style={{ width: "auto" }}
                    >
                      <div
                        className="bar"
                        style={{
                          width: `${details.count}%`,
                          backgroundColor: "blue",
                        }}
                      ></div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Progressbar;

import React, { useEffect, useState } from "react";
import "../../../Assets/Global.css";
import SubjectContent from "./SubjectContent.tsx";
import SubjectList from "./SubjectList.tsx";
import { useParams } from "react-router-dom";
import FolderContent from "../../../Shared/Components/FolderContainer/FolderContent.tsx";
import { ModuleType } from "../../../Shared/Utils/Constants.tsx";

const Subject = () => {
  const [status, setStatus] = useState(false);
  const [element, setElement] = useState<any>(null);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setElement(<FolderContent rootFolderId={id} moduleType={ModuleType.SUBJECT} />);
    } else {
      setElement(
        <>
          <SubjectContent
            callbackfunction={setStatus}
            callbackstatus={status}
          />
          <SubjectList render={status} />
        </>
      );
    }
  }, [id, status]);

  return (
    <div>
      {/* <section className="container mt-3">
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <h4 className="me-3 border-end py-3 pe-3 hero-header">
              Wellbeing is at the Heart of <br />
              everything we do
            </h4>
            <p className="hero-para sml">
              Firbank students embody our motto - conquering themselves, <br />
              their challenges and embracing possibilities.
            </p>
          </div>{" "}
        </div>
      </section>
      <section className="container ">
        <div className="row">
          <div className="col-12">
            <img src={HeroBannerSubject} alt="" className="img-fluid w-100" />
          </div>
        </div>
      </section> */}

      {element}
    </div>
  );
};

export default Subject;

import React, { useEffect, useState, useCallback } from "react";
import "../../../Assets/Global.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Folder from "../../../Shared/Components/FolderContainer/Folder.tsx";
import File from "../../../Shared/Components/FolderContainer/File.tsx";
// @ts-ignore
import CreateFolderIcon from "../../../Assets/images/create_new_folder.svg";
// @ts-ignore
import UploadFileIcon from "../../../Assets/images/Vector.svg";
import CustomModal from "../../../Component/CustomModal.tsx";
import FileUploadModal from "../../../Component/FileUploadModal.tsx";
import CreateFolderLayout from "../../../Layout/CreateFolderLayout.tsx";
import UploadFilelayout from "../../../Layout/UploadFilelayout.tsx";
import {
  AcceesLevel,
  FILE_FORM_DATA,
  FOLDER_FORM_DATA,
  VARIABLES,
} from "../../Utils/Constants.tsx";
import {
  createFolder,
  deleteFolder,
  getFolderContent,
  loadFolder,
  loadFolderContent,
  updateFolder,
} from "./FolderFunctions.tsx";
import { getRoutingPart } from "../Utilities/Functions.tsx";
import CommonService from "../../Services/CommonService.tsx";
import { useUploaderContext } from "../../../Component/ProgressBarProvider.tsx";
import CircularProgress from "@mui/material/CircularProgress";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { checkValidation } from "../Utilities/Validator.tsx";
import { toast } from "react-toastify";
import { ModuleType } from "../../../Shared/Utils/Constants.tsx";

const FolderContent = ({ rootFolderId, moduleType }) => {
  const [folderData, setFolderData] = useState<any[]>([]);
  const [fileData, setFileData] = useState<any[]>([]);
  const [path, setPath] = useState<any[]>([]);
  const [folderDetail, setFolderDetail] = useState<any>();
  const { handleDetails } = useUploaderContext();

  const [currentFolderId, setCurrentFolderId] = useState(rootFolderId);
  const [currentEditFolderId, setCurrentEditFolderId] = useState(rootFolderId);
  const [currentLayout, setCurrentLayout] = useState<any>();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(FOLDER_FORM_DATA);
  const [isProgerss, setIsProgress] = useState(false);
  const [fileFormData, setFileFormData] = useState(FILE_FORM_DATA);

  const [isRenderPage, setIsRenderPage] = useState(false);
  const usertype: any = localStorage.getItem("user-type-id");

  const dataFetcher = new CommonService();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (rootFolderId) {
      setIsProgress(true);
      setCurrentFolderId(rootFolderId);
      loadFolder(rootFolderId, setFolderDetail);
      loadFolderContent(
        rootFolderId,
        setFolderData,
        setFileData,
        setPath,
        setIsProgress
      );
    }
  }, [rootFolderId, isRenderPage]);

  const refreshFolderContent = () => {
    dataFetcher.getFolderContent(currentFolderId).then((result) => {
      setFolderData(result.Folders);
      setFileData(result.Files);
    });
    setIsRenderPage(!isRenderPage);
  };

  const onClickRedirect = (Id) => {
    setIsProgress(true);
    setCurrentFolderId(Id);
    const path = getRoutingPart(location, "", VARIABLES.COM_PATH);
    navigate(`/${path}/${Id}`);
    loadFolderContent(Id, setFolderData, setFileData, setPath, setIsProgress);
  };

  const handleOpenModal = (type) => {
    setShowModal(!showModal);
    switch (type) {
      case "CREATE":
        setCurrentLayout({ title: "Create Folder", type: "CREATE" });
        setFormData(FOLDER_FORM_DATA);
        break;
      case "UPDATE_FOLDER":
        setCurrentLayout({ title: "Update Folder", type: "UPDATE_FOLDER" });
        break;
      case "UPLOAD":
        setCurrentLayout({ title: "Upload File", type: "UPLOAD" });
        setFileFormData(FILE_FORM_DATA);
        break;
      case "UPDATE_UPLOAD":
        setCurrentLayout({ title: "Update File", type: "UPDATE_UPLOAD" });
        setFileFormData(FILE_FORM_DATA);
        break;
      default:
        break;
    }
  };

  const editInfo = (event) => {
    setCurrentEditFolderId(event?.Id);
    setFormData({
      folderName: {
        id: "",
        isRequired: true,
        value: event?.Name,
        isDisabled: false,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
      folderDescription: {
        id: "",
        isRequired: true,
        value: event?.Description,
        isDisabled: false,
        isInvalid: false,
        isError: false,
        errorMessage: "",
      },
    });
  };

  const handleCreateFolder = () => {
    const response = checkValidation(formData, setFormData);
    if (response) {
      createFolder(
        formData,
        currentFolderId,
        refreshFolderContent,
        setShowModal,
        setIsProgress
      );
    }
  };

  const handleUpdateFolder = () => {
    const response = checkValidation(formData, setFormData);
    if (response) {
      updateFolder(
        formData,
        currentFolderId,
        currentEditFolderId,
        refreshFolderContent,
        setShowModal,
        setIsProgress
      );
    }
  };

  const functionHandler = () => {
    switch (currentLayout?.type) {
      case "CREATE":
        handleCreateFolder();
        break;
      case "UPDATE_FOLDER":
        handleUpdateFolder();
        break;
      case "UPLOAD":
        const data = {
          file: fileFormData?.file?.value,
          Name: fileFormData?.fileName?.value,
          setShowModal: setShowModal,
          refreshFolderContent: refreshFolderContent,
          folderId: currentFolderId,
        };

        if (fileFormData?.file?.value === "") {
          toast.warning("Select a file first");
          return;
        }
        // console.log("Sample Sample")
        dataFetcher
          .fileUploader(data, handleDetails)
          .then(() => {
            setShowModal(false);
          })
          .catch(() => {
            console.log("Error Upload");
          });
        break;
      default:
        break;
    }
  };

  const handleDeleteFolder = async (selectedFolder, ParentFolderId) => {
    const response = await getFolderContent(selectedFolder);
    if (response?.Files?.length !== 0) {
      toast.warning(
        "The folder contains files. Please remove or relocate the files before deleting the folder."
      );
      return;
    } else if (response?.Folders?.length !== 0) {
      toast.warning(
        "The folder contains another folders. Please remove or relocate the folders before deleting the folder."
      );
      return;
    }

    deleteFolder(selectedFolder);
    refreshFolderContent();
    // loadFolderContent(ParentFolderId, setFolderData, setFileData, setPath, setIsProgress);
  };

  return (
    <div>
      <section className="container-fluid my-3">
        <div className="row justify-content-between">
          <div className="col-12 col-lg-8">
            <h5>{folderDetail?.Name}</h5>
            <p className="small rounded-sm">{folderDetail?.Description}</p>
          </div>
          <div className="col-12 col-lg-4 d-flex align-items-center justify-content-start justify-content-md-end pt-3">
            {AcceesLevel.some((item) =>
              moduleType === ModuleType.VCE
                ? parseInt(usertype) === item.Id && item.AllowTo.includes("ALL")
                : parseInt(usertype) === item.Id &&
                  item.AllowTo.includes("CRT_FOLDR")
            ) && (
              <button
                type="button"
                className="btn btn-outline-secondary me-2 d-flex align-items-center"
                onClick={() => handleOpenModal("CREATE")}
              >
                <img src={CreateFolderIcon} className="me-2" alt="" />
                <span>Create Folder</span>
              </button>
            )}

            {AcceesLevel.some((item) =>
              moduleType === ModuleType.VCE
                ? parseInt(usertype) === item.Id && item.AllowTo.includes("ALL")
                : parseInt(usertype) === item.Id &&
                  item.AllowTo.includes("UPD_FILE")
            ) && (
              <button
                type="button"
                className="btn btn-outline-secondary d-flex align-items-center "
                onClick={() => handleOpenModal("UPLOAD")}
              >
                <img src={UploadFileIcon} alt="" className="me-2" />
                <span>Upload File(s)</span>
              </button>
            )}
          </div>
        </div>
      </section>

      <section className="container-fluid mb-3  d-flex">
        <div className="container-wrapper py-1 px-1 ">
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb mb-0 ">
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {path &&
                  path?.map((item, index) => {
                    return (
                      <>
                        <Link
                          className="mouse-cursor breadcrumb-font fw-light"
                          key={index}
                          onClick={(event) => onClickRedirect(item?.FolderId)}
                        >
                          {item.Name}
                        </Link>
                      </>
                    );
                  })}
              </Breadcrumbs>
            </ol>
          </nav>
        </div>
      </section>

      <section className="container-fluid mb-3">
        <div className="row">
          <div className="col-12 ">
            <h6>Folders</h6>
          </div>
          <div className="col-12">
            <div className="d-flex justify-content-start flex-row w-100 flex-wrap">
              {isProgerss ? (
                <div className="loading-container">
                  <CircularProgress color="primary" size={30} />
                </div>
              ) : (
                <>
                  {folderData.length > 0 ? (
                    folderData?.map((item, index) => {
                      return (
                        <div
                          className="p-2 bg-light rounded mb-2 me-2 card-hover"
                          style={{ width: "260px" }}
                          key={index}
                        >
                          <Folder
                            folderInfo={item}
                            selectedFolder={onClickRedirect}
                            onUpdate={handleOpenModal}
                            editInfo={editInfo}
                            onDeleteCallBack={handleDeleteFolder}
                            moduleType={moduleType}
                          ></Folder>
                        </div>
                      );
                    })
                  ) : (
                    <div className="w-100">
                      {" "}
                      <div className=" container-wrapper p-3 rounded text-start">
                        No Folders To Show
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid mb-3">
        <div className="row ">
          <div className="col-12 ">
            <h6>Files</h6>
          </div>
          <div className="col-12">
            <div className="d-flex justify-content-start flex-row w-100 flex-wrap">
              {isProgerss ? (
                <div className="loading-container">
                  <CircularProgress color="primary" size={30} />
                </div>
              ) : (
                <>
                  {fileData.length > 0 ? (
                    fileData?.map((item, index) => {
                      return (
                        <div
                          className="p-2 bg-light rounded mb-2 me-2 card-hover"
                          style={{ width: "260px" }}
                          key={index}
                        >
                          <File
                            fileInfo={item}
                            onDeleteCallBack={refreshFolderContent}
                            moduleType={moduleType}
                          ></File>
                        </div>
                      );
                    })
                  ) : (
                    <div className="w-100">
                      {" "}
                      <div className="text-start container-wrapper p-3 rounded">
                        No Files To Show
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      {currentLayout?.type === "CREATE" ||
      currentLayout?.type === "UPDATE_FOLDER" ? (
        <CustomModal
          IsOpen={showModal}
          IsClose={handleOpenModal}
          Title={currentLayout?.title}
          isProgress={isProgerss}
          onSuccessCallBack={functionHandler}
          element={
            <CreateFolderLayout formData={formData} setFormData={setFormData} />
          }
          isDisabled={false}
        />
      ) : (
        <FileUploadModal
          IsOpen={showModal}
          IsClose={handleOpenModal}
          Title={currentLayout?.title}
          isProgress={isProgerss}
          onSuccessCallBack={functionHandler}
          element={
            <UploadFilelayout
              formData={fileFormData}
              setFormData={setFileFormData}
            />
          }
          isDisabled={false}
        />
      )}
    </div>
  );
};

export default FolderContent;

import React from "react";

const SubjectFormLayout = (props: { setFormData , formData}) => {
  const onChange = (event) => {
    props.setFormData({
      ...props.formData,
      [event.target.id]: {
        ...props.formData[event.target.id],
        value: event.target.value,
      },
    });
  };

  return (
    <div>
      <div className="mb-1">
        <label className="col-form-label">Subject Name</label>
        <input
          type="text"
          id="subjectName"
          value={props?.formData?.subjectName?.value}
          className="form-control"
          aria-describedby="passwordHelpInline"
          onChange={onChange}
        />
        {props?.formData?.subjectName?.isError && (
          <div className="error-message">
            {props?.formData?.subjectName?.errorMessage} *
          </div>
        )}
      </div>
      <div className="mb-3">  
        <label className="col-form-label">Description</label>
        <textarea
          id="description"
          value={props?.formData?.description?.value}
          className="form-control"
          aria-describedby="passwordHelpInline"
          onChange={onChange}
        />
        {props?.formData?.description?.isError && (
          <div className="error-message">
            {props?.formData?.description?.errorMessage} *
          </div>
        )}
      </div>
    </div>
  );
};

export default SubjectFormLayout;

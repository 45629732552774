export const checkValidation = (formData, setFormData) => {
  let isValid: any = [];
  const validateFormdate = { ...formData };

  for (const fieldId in validateFormdate) {
    if (validateFormdate.hasOwnProperty(fieldId)) {
      const field = validateFormdate[fieldId];
      if (field.isRequired && typeof field.value === "object") {
        const response = isNullOrEmpty(field.value);
        if (response) {
          validateFormdate[fieldId] = {
            ...validateFormdate[fieldId],
            isError: true,
            errorMessage: "This field is required.",
          };
          isValid.push(...isValid, "false");
        } else {
          validateFormdate[fieldId] = {
            ...validateFormdate[fieldId],
            isError: false,
            errorMessage: "",
          };
          isValid.push(...isValid, "true");
        }
      } else {
        if (field.isRequired && (field.value === null || field.value === "")) {
          validateFormdate[fieldId] = {
            ...field,
            isError: true,
            errorMessage: "This field is required.",
          };
          isValid.push(...isValid, "false");
        } else {
          validateFormdate[fieldId] = {
            ...field,
            isError: false,
            errorMessage: "",
          };
          isValid.push(...isValid, "true");
        }
      }
    }
  }

  setFormData(validateFormdate);
  const bool = isValid.includes("false");

  return !bool;
};

const isNullOrEmpty = (value) => {
  return (
    value === null ||
    value === "" ||
    (typeof value === "object" && Object.values(value).every(isNullOrEmpty))
  );
};

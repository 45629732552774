import React, { useEffect, useState } from "react";
import {
  AcceesLevel,
  SCHOOL_FORM_DATA,
} from "../../../Shared/Utils/Constants.tsx";
import CreateSubjectIcon from "../../../Assets/images/subjectIcon.svg";
import CustomModal from "../../../Component/CustomModal.tsx";
import SchoolFormLayout from "../Layouts/SchoolFormLayout.tsx";
import { createSchool } from "./SchoolFunctions.tsx";
import { checkValidation } from "../../../Shared/Components/Utilities/Validator.tsx";

const SchoolContent = ({ callbackfunction, callbackstatus }) => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(SCHOOL_FORM_DATA);
  const [isProgerss, setIsProgress] = useState(false);
  const usertype: any = localStorage.getItem("user-type-id");

  const handleOpen = () => {
    setShowModal(true);
    setFormData(SCHOOL_FORM_DATA);
  };

  const handleClick = () => {
    const response = checkValidation(formData, setFormData);
    if (response) {
      createSchool(
        formData,
        setShowModal,
        callbackfunction,
        callbackstatus,
        setIsProgress
      );
    }
  };

  return (
    <div>
      <section className="container-fluid my-3">
        <div className="row">
          <div className="col-12 col-sm-6 ">
            <h5 className="">SCHOOLS</h5>
            {/*            
            <p className="small mb-1">
            Find what you need faster … Home is your new landing page and surfaces your most relevant files and folders
            </p>  */}
          </div>
          {AcceesLevel.some(
            (item) =>
              parseInt(usertype) === item.Id && item.AllowTo.includes("ALL")
          ) && (
            <div className="col-12 col-sm-6 d-flex align-items-center justify-content-end ">
              <button
                className="btn btn-outline-secondary me-2 d-flex align-items-center "
                onClick={handleOpen}
              >
                <img src={CreateSubjectIcon} className="me-2" alt="" />
                <span>Add New School</span>
              </button>
            </div>
          )}
        </div>
      </section>
      <CustomModal
        IsOpen={showModal}
        IsClose={setShowModal}
        Title={"Create School"}
        isProgress={isProgerss}
        onSuccessCallBack={() => handleClick()}
        element={
          <SchoolFormLayout setFormData={setFormData} formData={formData} />
        }
        isDisabled={false}
      />
    </div>
  );
};

export default SchoolContent;

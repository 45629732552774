import "./App.css";
import React, { useEffect, useState } from "react";
import SignIn from "./Authentication/SignIn.tsx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./Authentication/authConfig.js";
import CommonService from "./Shared/Services/CommonService.tsx";
import App from "./App.js";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import SignOutPage from "./Authentication/SignOutPage.tsx";
import SignInPage from "./Authentication/SignInPage.tsx";

const AuthenticationLayer = () => {
  const { accounts, instance, inProgress } = useMsal();
  const [token, setToken] = useState(null);
  const [isProgress, setIsProgress] = useState(false);
  const dataFetcher = new CommonService();

  useEffect(() => {
    setIsProgress(false);
    if (accounts.length !== 0) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then(async (response) => {
          const bearer = `Bearer ${response.accessToken}`;
          setToken(bearer);
          localStorage.setItem("accessToken", bearer);
          localStorage.setItem("user_details_email", accounts[0]?.username);
          localStorage.setItem(
            "user_details_azure_id",
            accounts[0]?.localAccountId
          );
          localStorage.setItem("user_details_displayName", accounts[0]?.name);

          let userResponse;
          if (accounts[0]?.username) {
            userResponse = await dataFetcher.getUserByEmail(
              accounts[0]?.username,
              bearer
            );
          }

          localStorage.setItem("user-type-id", userResponse?.UserTypeId);
          setIsProgress(true);
        })
        .catch((error) => {
          setIsProgress(true);
          console.log("Custom Error : ", error);
        });
    } else {
      setIsProgress(true);
    }
  }, [accounts]);

  return (
    <>
      {!isProgress ? (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="primary" size={100} />
        </Backdrop>
      ) : (
        <>
          <AuthenticatedTemplate>
            <App token={token} />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<SignInPage/>} />
                <Route path="/login" element={<>Sign in using Redirect</>} />
                <Route path="/signout" element={<SignOutPage />} />
              </Routes>
            </BrowserRouter>
          </UnauthenticatedTemplate>
        </>
      )}
    </>
  );
};

export default AuthenticationLayer;

import { BaseDataFetcher } from "./BaseDataFetcher.tsx";
import { config } from "../Utils/config.tsx";
import ServiceClient from "./ServiceClient.tsx";
import { toast } from "react-toastify";
import { uploadFile } from "../Components/FileUploader/Uploader.tsx";
export default class CommonService extends BaseDataFetcher {
  // POST Request
  async createFolder(data: any): Promise<any> {
    const response = await ServiceClient.POST(
      config.folder.BASE_URL + config.folder.END_POINTS.CREATE_FOLDER,
      data
    );

    return response;
  }

  async createFile(data: any): Promise<any> {
    const response = await ServiceClient.POST(
      config.file_uploader.BASE_URL +
        config.file_uploader.END_POINTS.FILE_UPLOAD,
      data
    );

    return response;
  }

  // GET Request
  async getFolderContent(rootFolderId: any): Promise<any> {
    const response = ServiceClient.GET(
      config.folder.BASE_URL + config.folder.END_POINTS.GET_FOLDER_CONTENT,
      rootFolderId
    );
    return response;
  }

  async getFolder(parentId: any): Promise<any> {
    const response = ServiceClient.GET(
      config.folder.BASE_URL + config.folder.END_POINTS.GET_FOLDER,
      parentId
    );
    return response;
  }

  async getFiles(parentId: any): Promise<any> {
    const response = ServiceClient.GET(
      config.file_uploader.BASE_URL +
        config.file_uploader.END_POINTS.FILE_UPLOAD,
      parentId
    );
    return response;
  }

  async getFile(parentId: any): Promise<any> {
    const response = ServiceClient.GET(
      config.file_uploader.BASE_URL + config.file_uploader.END_POINTS.GET_FILE,
      parentId
    );
    return response;
  }

  async downloadFile(
    url: any,
    contentType: any,
    details: any,
    handleDetails: any
  ): Promise<any> {
    const response = ServiceClient.DOWNLOAD(
      url,
      contentType,
      details,
      handleDetails
    );
    return response;
  }

  // POST Request
  async updateFolder(data: any): Promise<any> {
    const response = await ServiceClient.PUT(
      config.folder.BASE_URL + config.folder.END_POINTS.UPDATE_FOLDER,
      data
    );

    return response;
  }

  // DELETE Request
  async deleteFolder(folderId: any): Promise<any> {
    const response = await ServiceClient.DELETE(
      config.folder.BASE_URL +
        config.folder.END_POINTS.DELETE_FOLDER +
        `${folderId}`
    );

    return response;
  }

  async deleteFile(fileId: any): Promise<any> {
    const response = await ServiceClient.DELETE(
      config.file_uploader.BASE_URL +
        config.file_uploader.END_POINTS.DELETE_FILE +
        `${fileId}`
    );

    return response;
  }

  // Uploader
  async fileUploader(file: any, handleCount: any): Promise<any> {
    uploadFile(file, handleCount);
  }

  async getUserByEmail(Email: any): Promise<any> {
    const response = ServiceClient.GET(
      config.users.BASE_URL + config.users.END_POINTS.GET_USER_BY_EMAIL,
      Email
    );
    return response;
  }
}

import React, { useEffect, useState } from "react";
import MoreIcon from "../../../Assets/images/coolicon.svg";
import DownloadIcon from "../../../Assets/images/cloud_download.svg";
import Delete from "../../../Assets/images/crud/trash_full.svg";
import ViewIcon from "../../../Assets/images/crud/show.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  deleteFile,
  getFileContent,
  getFileContentForView,
} from "./FolderFunctions.tsx";
import { useUploaderContext } from "../../../Component/ProgressBarProvider.tsx";
import CustomModal from "../../../Component/CustomModal.tsx";
import ConfirmationModal from "../../../Component/ConfirmationModal.tsx";
import FileViewer from "../FileViewer/FileViewer.tsx";
import { AcceesLevel, FILE_ICON } from "../../Utils/Constants.tsx";
import Tooltip from "@mui/material/Tooltip";
import { ModuleType } from "../../../Shared/Utils/Constants.tsx";

const File = ({ fileInfo, onDeleteCallBack, moduleType }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isProgerss, setIsProgress] = useState(false);
  const [fileDetails, setFileDetails] = useState<any>(null);
  const usertype: any = localStorage.getItem("user-type-id");
  const [iconSVG, setIconSVG] = useState<any>(null);
  const open = Boolean(anchorEl);
  const { handleDetails } = useUploaderContext();

  useEffect(() => {
    setIconSVG(FILE_ICON[fileInfo?.FileExtention.replace(".", "")]);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = async () => {
    await getFileContent(fileInfo?.Id, handleDetails);
    setAnchorEl(null);
  };

  const handleView = async () => {
    setShowModal(!showModal);
    await getFileContentForView(fileInfo?.Id, setFileDetails);
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    setShowConfirmModal(true);
    setAnchorEl(null);
  };

  return (
    <div className="bg-light px-1 rounded  ">
      <div
        className="bg-white px-1 py-2 rounded d-flex flex-row justify-content-center align-items-center "
        onDoubleClick={() => handleView()}
      >
       <Tooltip title={fileInfo?.Name} placement="top">
          <a className="text-decoration-none mouse-cursor d-flex justify-content-between align-items-center flex-row">
            <img
              src={fileInfo?.img}
              className=""
              alt=""
              style={{ height: "50px" }}
            />
            <div
              className="py-3 px-2"
              style={{
              paddingRight:"5px",
                width:"150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap", 
              }}
            >
              <span className=" text-dark ">{fileInfo?.Name}</span>
            </div>
          </a>
        </Tooltip>
        <div className="text-end">
          <Tooltip title="More" placement="top">
            <a
              id="basic-button"
              className="btn-link px-1"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img src={MoreIcon} alt="" />
            </a>
          </Tooltip>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <>
            {AcceesLevel.some(
              (item) =>
                parseInt(usertype) === item.Id &&
                item.AllowTo.includes("FILE_VIEW_DOWNLOAD")
            ) && (
              <MenuItem onClick={handleDownload}>
                <a className="dropdown-item align-items-center d-flex">
                  <img src={DownloadIcon} alt="" className="me-2" />
                  <span className="small">Download</span>
                </a>
              </MenuItem>
            )}

            {AcceesLevel.some(
              (item) =>
                parseInt(usertype) === item.Id &&
                item.AllowTo.includes("FILE_VIEW_DOWNLOAD")
            ) && (
              <MenuItem onClick={handleView}>
                <a className="dropdown-item align-items-center d-flex">
                  <img src={ViewIcon} alt="" className="me-2" />{" "}
                  <span className="small">View</span>
                </a>
              </MenuItem>
            )}

            {AcceesLevel.some(
              (item) => moduleType === ModuleType.VCE ? parseInt(usertype) === item.Id && item.AllowTo.includes("ALL") : 
                parseInt(usertype) === item.Id &&
                item.AllowTo.includes("FILE_DELETE")
            ) && (
              <MenuItem onClick={handleDelete}>
                <a className="dropdown-item align-items-center d-flex">
                  <img src={Delete} alt="" className="me-2" />{" "}
                  <span className="small text-danger">Delete</span>
                </a>
              </MenuItem>
            )}
          </>
        </Menu>

      
      </div>
      <CustomModal
          IsOpen={showModal}
          IsClose={setShowModal}
          Title={fileDetails?.FileMetaData?.OriginalFileName}
          isProgress={false}
          onSuccessCallBack={() => {}}
          element={<FileViewer fileInfo={fileDetails} />}
          isDisabled={true}
        />

        <ConfirmationModal
          IsOpen={showConfirmModal}
          IsClose={setShowConfirmModal}
          Title={"Delete File"}
          isProgress={isProgerss}
          element={"Do you want to delete this ?"}
          onSuccessCallBack={async () => {
            setIsProgress(true);
            await deleteFile(fileInfo?.Id);
            onDeleteCallBack();
          }}
        />
    </div>
    
  );
};

export default File;

// @ts-ignore
import { CommonDataFetchingInterface } from '../../Interfaces/CommonDataFetchingInterface.js';

export class BaseDataFetcher implements CommonDataFetchingInterface {
    createFolder(data: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    getFolderContent(rootFolderId: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    getFolder(parentId: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    getUserByEmail(Email: any, token:any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    deleteFolder(folderId: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    deleteFile(fileId: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    updateFolder(data: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    createFile(data: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    getFiles(parentId: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    getFile(parentId: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    fileUploader(data: any, handleCount: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
}
import React, { useState } from "react";
import CreateSubjectIcon from "../../../Assets/images/subjectIcon.svg";
import {
  AcceesLevel,
  SUBJECT_FORM_DATA,
} from "../../../Shared/Utils/Constants.tsx";
import CustomModal from "../../../Component/CustomModal.tsx";
import SubjectFormLayout from "../Layouts/SubjectFormLayout.tsx";
import { createSubject } from "./SubjectFunctions.tsx";
import { checkValidation } from "../../../Shared/Components/Utilities/Validator.tsx";

const SubjectContent = ({ callbackfunction, callbackstatus }) => {
  const [showModal, setShowModal] = useState(false);
  const [isProgerss, setIsProgress] = useState(false);
  const [formData, setFormData] = useState(SUBJECT_FORM_DATA);
  const usertype: any = localStorage.getItem("user-type-id");

  const handleOpen = () => {
    setShowModal(true);
    setFormData(SUBJECT_FORM_DATA);
  };

  const handleClick = () => {
    const response = checkValidation(formData, setFormData);
    if (response) {
      createSubject(
        formData,
        setShowModal,
        callbackfunction,
        callbackstatus,
        setIsProgress
      );
    }
  };

  return (
    <div className="main">
      <section className="container-fluid my-3">
        <div className="row">
          <div className="col-12 col-lg-6  px-3">
            <h5 className="">SUBJECT</h5>
            {/*             
            <p className="small mb-1">
            Find what you need faster … Home is your new landing page and surfaces your most relevant files and folders
            </p>  */}
          </div>
          {AcceesLevel.some(
            (item) =>
              parseInt(usertype) === item.Id && item.AllowTo.includes("ALL")
          ) && (
            <div className="col-12 col-lg-6 d-flex align-items-center justify-content-start justify-content-md-end">
              <button
                className="btn btn-outline-secondary me-2 d-flex align-items-center "
                onClick={handleOpen}
              >
                <img src={CreateSubjectIcon} className="me-2" alt="" />
                <span>Add New Subject</span>
              </button>
            </div>
          )}
          {/* <div className="col-12 col-lg-5 d-flex align-items-center justify-content-start">
            <input
              className="form-control"
              list="datalistOptions"
              id="exampleDataList"
              placeholder="Type to search..."
            />
            <datalist id="datalistOptions">
              <option value="San Francisco" />
              <option value="New York" />
              <option value="Seattle" />
              <option value="Los Angeles" />
              <option value="Chicago" />
            </datalist>
          </div> */}
        </div>
      </section>

      <CustomModal
        IsOpen={showModal}
        IsClose={setShowModal}
        Title={"Create Subject"}
        isProgress={isProgerss}
        onSuccessCallBack={() => handleClick()}
        element={
          <SubjectFormLayout setFormData={setFormData} formData={formData} />
        }
        isDisabled={false}
      />
    </div>
  );
};

export default SubjectContent;

export const config = {
  subject: {
    BASE_URL: process.env.REACT_APP_SUBJECT_API_URL,
    END_POINTS: {
      CREATE_SUBJECT: "create-subject",
      DELETE_SUBJECT: "delete-subject",
      GET_ALL_SUBJECT: "get-all-subjects",
      GET_SUBJECT: "get-subject",
      UPDATE_SUBJECT: "update-subject",
    },
  },

  school: {
    BASE_URL: process.env.REACT_APP_SCHOOL_API_URL,
    END_POINTS: {
      CREATE_SCHOOL: "create-school",
      DELETE_SCHOOL: "delete-school",
      GET_ALL_SCHOOL: "get-all-schools",
      GET_SCHOOL: "get-school/",
      UPDATE_SCHOOL: "update-school",
    },
  },

  folder: {
    BASE_URL: process.env.REACT_APP_FOLDER_API_URL,
    END_POINTS: {
      CREATE_FOLDER: "create-folder",
      DELETE_FOLDER: "delete-folder/",
      GET_FOLDER_CONTENT: "folder-content/",
      GET_FOLDER: "get-folder/",
      UPDATE_FOLDER: "update-folder",
    },
  },

  file_uploader: {
    BASE_URL: process.env.REACT_APP_FILE_UPLOADER_URL,
    END_POINTS: {
      UPLOAD_FILE_URL: "upload-file-url/?",
      FILE_UPLOAD: "file-upload",
      GET_FILE: "get-file/",
      DELETE_FILE: "delete-file/",
    },
  },

  users: {
    BASE_URL: process.env.REACT_APP_USERS_API_URL,
    END_POINTS: {
      CREATE_USER: "create-user",
      DELETE_USER: "delete-user",
      GET_ALL_USERS: "get-all-users/",
      GET_ALL_USER_TYPE: "get-user-types/",
      GET_USER: "get-user/",
      UPDATE_USER: "update-user",
      GET_USER_BY_EMAIL: "get-user-by-portal-email/",
    },
  },
};

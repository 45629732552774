// @ts-ignore
import { SchoolDataFetchingInterface } from "../../../Interfaces/SchoolDataFetchingInterface.tsx";

export class SchoolDataFetcher implements SchoolDataFetchingInterface {
  createSchool(data: any): Promise<any> {
    throw new Error("Method not implemented.");
  }
  getAllSchool(): Promise<any> {
    throw new Error("Method not implemented.");
  }
  getSchool(parentId: any): Promise<any> {
    throw new Error("Method not implemented.");
  }
  updateSchool(data: any): Promise<any> {
    throw new Error("Method not implemented.");
  }
  deleteSchool(schoolId: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

}
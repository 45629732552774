import React from "react";

const UploadFilelayout = (props: { setFormData; formData }) => {
  const onChange = (event) => {
    props.setFormData({
      ...props.formData,
      [event.target.id]: {
        ...props.formData[event.target.id],
        value: event.target.files[0],
      },
    });
  };

  return (
    <div>
      <div className="mb-1">
        <label className="col-form-label">File</label>
        <input
          type="file"
          id="file"
          className="form-control"
          onChange={onChange}
          aria-describedby="passwordHelpInline"
        />
      </div>
      {/* <div className="mb-3">
        <label className="col-form-label">Name</label>
        <input
          type="text"
          id="fileName"
          className="form-control"
          onChange={onChange}
          aria-describedby="passwordHelpInline"
        />
      </div> */}
    </div>
  );
};

export default UploadFilelayout;

import React, { createContext, useContext, useState } from "react";
import { FileUploaderContextProps } from "../Models/Interfaces.tsx";

const defaultValue: FileUploaderContextProps = {
  details: {
    count: 0,
    isProgressBar: false,
    filename: '',
    isUpload: false,
    isEnabled: false
  },
  handleDetails: () => {},
};



const UploaderProvider = createContext<FileUploaderContextProps>(defaultValue);

export const useUploaderContext = () => {
  return useContext(UploaderProvider);
};

export const UploaderProviderContext = ({ children }) => {
  const [details, setDetails] = useState(defaultValue.details);

  const handleDetails = (progressDetails) => {
    setDetails({
        count: progressDetails.count,
        isProgressBar: progressDetails.isProgressBar,
        filename : progressDetails.filename,
        isUpload : progressDetails.isUpload,
        isEnabled: progressDetails.isEnabled
      });
  };

  const contextValue: FileUploaderContextProps = {
    details,
    handleDetails,
  };

  return (
    <UploaderProvider.Provider value={contextValue}>
      {children}
    </UploaderProvider.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

const SignOutButton = () => {
  const { instance } = useMsal();
  const [element , setElement] = useState(null);

  const Logout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/signout",
    });


  }
  return (
    <div>
      <button className="dropdown-item me-2 text-black" onClick={() => Logout()}>Sign Out</button>
    </div>
  );
};

export default SignOutButton;
